import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

export const ExtensionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;

  .text-muted {
    margin-bottom: 0px !important;
  }
`

export const Title = styled.h1`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: -0.04em;
  padding-bottom: 30px;
  width: 100%;

  font-size: 30px;
  align-self: flex-start;
`;

export const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: center;

  width: 100%;
  max-width: 1600px;
  padding: 20px;
  margin-top: 30px;
  gap: 20px;

  background: #1d2d1d;
  border-radius: 10px;
`

export const VideosHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  width: 100%;
  max-width: 1600px;
  padding: 20px;
  margin-top: 30px;

  background: #1d2d1d;
  border-radius: 10px;
`

export const DownloadButton = styled(Button)`
  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    background-color: rgb(23, 128, 61);
    color: white;
  }  
  `

export const BookieContainer = styled.div`
  display: flex;
  margin-top: 20px; 
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
`

export const HeaderPupsikSubContainer = styled.div`
  gap: 1px;
  align-items: end;
  // background-color: #111e12;
  background-color: white;
  color: #111e12;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
`

export const CapabilitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 7px;
  background-color: #111e12;
  border-radius: 5px;
  color: white;
`

export const Capability = styled.div`
  color: ${({ enabled }) => enabled ? 'white' : 'gray'};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;

  @media (max-width: 1260px) {
    flex-direction: column;
  }
`

export const LeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #111e12;
  border-radius: 10px;
  color: white;
  width: 100%;

  .btn {
    background-color: #E9FD8C;
    color: #111e12;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    transition: background-color 0.3s ease;

    :hover, :focus, :active, :focus-visible, :target, :not(:disabled):not(.disabled):active {
      background-color: #007053;
      color: #E9FD8C;
    }
  }
`

export const PointsTitle = styled.div`
  font-size: 35px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;

  @media (max-width: 550px) {
    font-size: 30px;
  }

  @media (max-width: 400px) {
    font-size: 25px;
  }
`

export const Points = styled.div`
  font-size: 40px;
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AvailablePointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #111e12;
  border-radius: 10px;
  color: white;
  width: 100%;
`

export const AvailablePointsTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  background-color: #1d2d1d;
  padding: 10px;
  border-radius: 5px 0px 0px 5px;
`

export const AvailablePoints = styled.div`
  font-size: 20px;
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E9FD8C;
  padding: 10px;
  color: #111e12;
  border-radius: 0px 5px 5px 0px;
`

export const PointsTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`

export const PointsTableItem = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  background-color: #1d2d1d;
  color: #111e12;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
`

export const PointsTableItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-height: 50px;
  align-self: stretch;

  background-color: #E9FD8C;
  border-radius: 5px 0px 0px 5px;
  font-size: 22px;

  color: #111e12;
  font-weight: bold;
  font-family: 'Montserrat';
`

export const PointsTableItemText = styled.div`
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 600;
  padding: 5px;
  padding-left: 10px;

  display: flex;
  gap: 5px;

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

export const HowItWorks = styled.div`
  font-size: 18px;
  text-align: center;
`

export const ReportsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: center;

  width: 100%;
  max-width: 1600px;
  padding: 20px;
  margin-top: 30px;
  gap: 20px;

  background: #1d2d1d;
  border-radius: 10px;
  overflow: scroll;
`

export const StatusBadge = styled(Badge)`
  font-size: 15px;
  text-align: center;
  width: 120px;

  color: ${({ status }) => ['PENDING', 'VERIFIED'].includes(status) ? '#111e12' : 'white'};

  background-color: ${({ status }) => {
    if (status === 'VERIFIED') return '#94E5AB';
    if (status === 'PENDING') return '#B8DCEF';
    return '#aa3333';
  }};

  @media (max-width: 1260px) {
    width: 100px;
    font-size: 12px;
  }
`

export const PointsControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;

export const PointsDisplay = styled.div`
  font-size: 24px;
  font-weight: bold;
  width: 100px;
  text-align: center;
`;

export const AnimationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
  margin-top: 20px;
`;

export const PointsAnimation = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 48px;
  font-weight: bold;
  color: #4caf50;
  animation: bounce 1s infinite;

  @keyframes bounce {
    0%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    50% {
      transform: translateX(-50%) translateY(-20px);
    }
  }
`;