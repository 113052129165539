import React, { useEffect, useState, useMemo, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import './UserBets.styled';
import { NavBarMain, BetCard } from '..';
import { useUser } from "../../hooks";
import {
  UserBetsContainer, Subcontainer, PlacedBetsContainer
} from './UserBets.styled'
import { checkIfTokenStillValid } from "../../repositories/utils";

function UserBetsContent({ userBets, settledBets, settleBet, isPlaced, setIsPlaced }) {
  const { t } = useTranslation();

  const getEmail = () => (userBets?._id ? userBets._id.email : '');

  const openBets = useMemo(() => userBets?.bets?.filter(bet => bet.status === 'PLACED' && !settledBets.some(({ version }) => version === bet.version)), [userBets, settledBets])

  const getLastUpdate = () =>
    userBets?.configuration &&
    userBets?.configuration?.proxy &&
    userBets?.configuration.proxy?.lastUpdate &&
    new Date(userBets?.configuration.proxy.lastUpdate).toLocaleString();

  const getProfit = () => userBets?.billing ? Math.round((userBets.billing.profit || 0) * 100) / 100 : 0;

  const getBets = () =>
    isPlaced === true
      ? userBets?.bets?.filter(bet => bet.status === 'PLACED' && !settledBets.some(({ version }) => version === bet.version))
      : userBets?.bets?.filter(bet => ['SETTLED', 'PAYOUT'].includes(bet.status) || settledBets.some(({ version }) => version === bet.version));

  const getUsername = () =>
    getEmail().includes('@')
      ? getEmail().substring(0, getEmail().lastIndexOf('@')).toUpperCase()
      : getEmail().toUpperCase();

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        staggerChildren: 0.1,
        when: "beforeChildren"
      }
    },
    exit: { opacity: 0, y: -20 }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <Subcontainer>
      <motion.div
        className="w-full rounded-xl shadow-lg overflow-hidden mt-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <header className="bg-[#1B2D1A] text-white p-4 flex justify-between items-center rounded-t-xl">
          <h1 className="text-xl font-semibold">{getUsername()}</h1>
          <div className="text-[#E8FC8B]">
            <span className="text-xs">{t("profit").toUpperCase()}</span>
            <p className="text-2xl font-bold">{getProfit()}€</p>
          </div>
        </header>
        <nav className="flex border-b border-[#1B2D1A] relative">
          <motion.div
            className="absolute bottom-0 h-1 bg-[#E8FC8B]"
            initial={false}
            animate={{
              x: isPlaced ? '0%' : '100%',
              width: '50%'
            }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          />
          <motion.button
            key={'ABIERTAS'}
            onClick={() => setIsPlaced(true)}
            className={`flex-1 py-2 px-4 focus:outline-none transition-colors text-[#1B2D1A] ${isPlaced && 'font-semibold'}`}
            whileTap={{ backgroundColor: '#E8FC8B' }}
            initial={{ backgroundColor: 'white' }}
            animate={{ backgroundColor: isPlaced ? '#E8FC8B' : 'white' }}
            transition={{ duration: 0.3 }}
          >
            {t("openBets").toUpperCase()} 
            {openBets?.length > 0 && (
              <motion.span 
                className="ml-2 px-2 py-1 bg-[#1B2D1A] text-white rounded-full text-xs"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
              >
                {openBets?.length}
              </motion.span>
            )}
          </motion.button>
          <motion.button
            key={'DETERMINADAS'}
            onClick={() => setIsPlaced(false)}
            className={`flex-1 py-2 px-4 focus:outline-none transition-colors text-[#1B2D1A] ${!isPlaced && 'font-semibold'}`}
            whileTap={{ backgroundColor: '#E8FC8B' }}
            initial={{ backgroundColor: 'white' }}
            animate={{ backgroundColor: !isPlaced ? '#E8FC8B' : 'white' }}
            transition={{ duration: 0.3 }}
          >
            {t("settledBets").toUpperCase()}
          </motion.button>
        </nav>
      </motion.div>
      <AnimatePresence mode="wait">
        <motion.div
          key={isPlaced ? 'open' : 'settled'}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.3, delay: 0.3 }}
          exit="exit"
        >
          <PlacedBetsContainer>
            {getBets()?.map(bet => (
              <motion.div key={bet.version} variants={itemVariants}>
                <BetCard 
                  bet={bet} 
                  setSettledBet={() => settleBet(getEmail(), bet)} 
                />
              </motion.div>
            ))}
          </PlacedBetsContainer>
        </motion.div>
      </AnimatePresence>
    </Subcontainer>
  );
}

function LoadingFallback() {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#E8FC8B]"></div>
    </div>
  );
}

export function UserBets({ history, match }) {
  const { getUserBets, settleBet, settledBets } = useUser();
  const [userBets, setUserBets] = useState(null);
  const [isPlaced, setIsPlaced] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      checkIfTokenStillValid(history);
      const res = await getUserBets(match.params.email);
      setUserBets(res);
      setIsLoading(false);
    }
    fetchData();
  }, [getUserBets, history, match.params.email]);

  return (
    <UserBetsContainer>
      <NavBarMain currentPage="bothome" history={history} />
      <Suspense fallback={<LoadingFallback />}>
        {!isLoading && (
          <UserBetsContent
            userBets={userBets}
            settledBets={settledBets}
            settleBet={settleBet}
            isPlaced={isPlaced}
            setIsPlaced={setIsPlaced}
          />
        )}
      </Suspense>
    </UserBetsContainer>
  );
}
