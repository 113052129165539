import styled from "styled-components";

export const BetCardContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
  padding: 15px;
  background: rgba(113, 107, 107, 0.08);
  border: 1px solid rgba(113, 107, 107, 0.08);

  ${props => props.status?.paused || props.arbStatus?.extra?.isNextGame || props.arbStatus?.extra?.isNextSet || props.arbStatus?.extra?.isNextQuarter || props.arbStatus?.extra?.isNextHalf 
      ? (props.status?.longPaused || props.arbStatus?.extra?.isNextGame || props.arbStatus?.extra?.isNextSet || props.arbStatus?.extra?.isNextQuarter || props.arbStatus?.extra?.isNextHalf 
        ? 'border: 2px solid red'
        : 'border: 2px solid #FFC933') 
      : ''};
  
  :hover {
    cursor: pointer;
    border: 1px solid #1bb78a;
  }
`

export const MatchOverview = styled.div`
  display: flex;
  padding-left: 10px;
  padding-bottom: 10px;
`

export const Match = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
`

export const FlexContainer = styled.div`
  font-weight: 600;
  display: flex;
  align-items: flex-end;
`

export const StatusContainer = styled.div`
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  gap: 5px;
`

export const EventTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  @media only screen and (min-width: 1500px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const DivisionContainer = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1500px) {
    margin-top: -3px;
    margin-bottom: 1px;  
  }
`

export const EventNameText = styled.div`
  margin-right: 5px;

  :hover {
    cursor: pointer;
    color: #1BB78A;
  }
`

export const CountryNameText = styled.div`
  color: #758b77;
  margin-right: 5px;

  @media only screen and (min-width: 1500px) {
    ::before {
      content: ' | ';
    }
  }

  ::after {
    content: ' |';
  }
`

export const LeagueNameText = styled.div`
  color: #758b77;
  margin-right: 5px;
`

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: ${props => props.filter};
`

export const PlacedBetsContainer = styled.div``

export const StatusBadge = styled.div`
  font-size: medium;
  ${props => props.status?.longPaused || props.arbStatus?.extra?.isNextGame || props.arbStatus?.extra?.isNextSet || props.arbStatus?.extra?.isNextQuarter || props.arbStatus?.extra?.isNextHalf ? 'border: 2px solid red': 'border: 2px solid #FFC933'};
  ${props => props.status?.longPaused || props.arbStatus?.extra?.isNextGame || props.arbStatus?.extra?.isNextSet || props.arbStatus?.extra?.isNextQuarter || props.arbStatus?.extra?.isNextHalf ? 'background-color: rgba(255, 0, 0, 0.2)': 'background-color: rgba(179, 117, 15, 0.2)'};
`
export const ExtraArbsBadge = styled.div`
  font-size: medium;
  background-color: #304332;
`

export const ScoreBadge = styled.div`
  font-size: medium;
  background-color: rgba(85, 85, 187, 0.2);
  border: 1px solid #5556bb;
`

export const SubScoreBadge = styled.div`
  font-size: medium;
  background-color: rgba(21, 21, 97, 0.2);
  border: 1px solid #646595;
`

export const StartTimeBadge = styled.div`
  font-size: medium;
  background-color: rgba(113, 116, 108, 0.4);
  border: 1px solid gray;
  width: auto;
`

export const GoalIconContainer = styled.div`
  margin-left: 5px;
  font-size: medium;
  background-color: red;
  width: auto;

  > b {
    margin-left: 5px;
    color: white;

    @keyframes slowlyAppear {
      0% {
        opacity: 0;
      }
      35% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  
   animation: slowlyAppear 2s infinite;  
    }
  
`

export const GoalIcon = styled.img`
  height: 13px;
  
  @keyframes slowlyAppear {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

 animation: slowlyAppear 2s infinite;  
`