import { useState, useEffect } from 'react';
import { useUser } from '../../../hooks';

const useSubscription = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState({});
  const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const { getActiveSubscription, getAvailableSubscriptions, cancelSubscription, freezeSubscription, unfreezeSubscription, redeemCoupon } = useUser();

  const [couponError, setCouponError] = useState(null);

  const fetchSubscriptionData = async () => {
    const status = await getActiveSubscription();
    setSubscriptionStatus(status);
    const subscriptions = await getAvailableSubscriptions();
    setAvailableSubscriptions(subscriptions);
    setSelectedSubscription(subscriptions?.find((s) => s.default) || subscriptions?.[0]);
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const isSubscriptionNonExisting = subscriptionStatus?.status === "NON_EXISTING";

  const handleSelectedSubscription = (event) => {
    setSelectedSubscription(availableSubscriptions[event.target.selectedIndex]);
  };

  const handleSubscriptionAction = async (action) => {
    switch (action) {
      case 'cancel': {
        await cancelSubscription(subscriptionStatus.id);
        setSubscriptionStatus(prev => ({ ...prev, status: "CANCELLED" }));
        break;
      }
      case 'freeze': {
        await freezeSubscription(subscriptionStatus.id);
        setSubscriptionStatus(prev => ({ ...prev, status: "FREEZED", updatedOn: new Date() }));
        break;
      }
      case 'unfreeze': {
        const response = await unfreezeSubscription(subscriptionStatus.id);
        setSubscriptionStatus({ id: subscriptionStatus.id, status: "CANCELLED", expiresOn: response.cutoffDate });
        break;
      }
      case 'pending': {
        setSubscriptionStatus({ status: "PENDING" });
        break;
      }
      default: {
        console.error('Unknown subscription action:', action);
      }
    }
  };

  const applyCoupon = async (couponCode) => {
    try {
      console.log('couponCode', couponCode);
      const response = await redeemCoupon(couponCode);
      if (response.success) {
        setSubscriptionStatus({ status: "CANCELLED", ...response.subscription });
        fetchSubscriptionData();
        setCouponError(null);
      } else {
      console.log('response', response);
        setCouponError(response.reason || 'Failed to apply coupon');
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      setCouponError('An error occurred while applying the coupon');
    }
  };

  return {
    subscriptionStatus,
    availableSubscriptions,
    selectedSubscription,
    handleSelectedSubscription,
    handleSubscriptionAction,
    isSubscriptionNonExisting,
    applyCoupon,
    couponError,
  };
};

export default useSubscription;