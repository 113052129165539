import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  padding: 20px 50px 50px 50px;
  font-family: 'Montserrat';

  @media only screen and (max-width: 700px) {
    padding: 20px 10px 10px 10px;
  }

  .customAlert > p {
    margin-bottom: 0;
  }

`;

export const BookieContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  gap: 40px;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
`;

export const BookiesSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #1C2D1C;
  padding: 20px;
  border-radius: 10px;
`;

export const BookiesHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BookieHeaderText = styled.h3`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: -0.04em;
  padding-bottom: 30px;
  width: 100%;
  font-size: 30px;
  align-self: flex-start;
`

export const LegendContainer = styled.div`
  .provider {
    border: solid 1px green;
    background-color: rgba(113, 107, 107, 0.06);
    padding: 10px;
  }
`

export const LegendLink = styled.a`
  margin-left: 10px; 
  margin-right: 10px;
`

export const BookieNameBadge = styled.span`
  margin-bottom: 2px;
  text-transform: capitalize;
  padding: 3px;
  font-size: large;
    background-color: #B8DCEF;
    color: #1C2D1C;
    padding: 10px;

`

export const BookiesListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 700px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: unset;
      margin-top: 20px;
  }
`