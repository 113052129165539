import styled from "styled-components";
import { Button } from "react-bootstrap";

export const UserBetsContainer = styled.div`
  width: 100%;
  padding: 20px 50px 50px 50px;
  background-color: #111E12;

  @media only screen and (max-width: 700px) {
    padding: 20px 10px 10px 10px;
  }

  .customAlert > p {
    margin-bottom: 0;
  }
`;

export const UserEmail = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
  }
`;

export const ConfigButton = styled(Button)`
  background-color: #1d2d1d;
  border: unset;

  :disabled {
    background-color: #1d2d1d;
    color: white;
  }

  :hover {
    background-color: #E9FD8C;
    color: #1d2d1d;
  }

  :active {
    background-color: #E9FD8C !important;
    color: #1d2d1d !important;
  }

  :focus {
    background-color: #E9FD8C;
    color
`;


export const EmailAndLastUpdate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

export const UserEmailText = styled.div`
  font-size: 25px;

  @media only screen and (max-width: 700px) {
    font-size: 18px;
    width: fit-content;
    text-align: center;
  }
`;

export const BoldLink = styled.a`
  font-weight: bold;
`;

export const RightSide = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  align-self: flex-end;

  @media only screen and (max-width: 700px) {
    align-self: flex-start;
  }
`;

export const SelectedEvent = styled.div`
  margin: 20px 0px;
  font-size: medium;
`;

export const UserProfit = styled.div`
  margin-right: 10px;

  @media only screen and (max-width: 700px) {
    position: unset;
    right: 0;
    margin-right: 2px;
  }
`;

export const UserBetsContainerNav = styled.div`
  margin-top: 5px;
  flex-wrap: nowrap !important;

  > .userEmail {
    align-items: center;
    margin-bottom: 20px;
  }

  > .leftSide {
    margin-left: 0px !important;
  }

  > .nav-item {
    cursor: pointer;
  }

  .active {
    border: solid 1px green;
    background-color: rgba(0, 107, 107, 0.1) !important;
  }
`;

export const StyledBadge = styled.a`
  margin-left: 5px;
  line-height: 2;
`;

export const Container = styled.div`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const AllArbsContainer = styled.div`
  width: ${(props) => (props.pinnedValuebet ? "50%" : "100%")};

  @media only screen and (max-width: 700px) {
    width: 100%;
    order: 2;
  }
`;
export const SelectedArbContainer = styled.div`
  width: 50%;

  @media only screen and (max-width: 700px) {
    width: 100%;
    order: 1;
  }
`;

export const EventsContainer = styled.div`
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;

  :hover {
    cursor: pointer;
    border: 2px solid white;
  }
`;

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: ${(props) => props.filter};
`;

export const ConnectionBadge = styled.div`
  margin-left: 10px;
  background-color: ${(props) => (props.connected ? "rgba(10, 203, 10, 0.1)" : "rgba(152, 72, 72, 0.1)")};
  border: ${(props) => (props.connected ? "1px solid green" : "1px solid red")};
  border-radius: 5px;
  padding: 5px;
`;

export const VisitorBadge = styled.div`
  margin-left: 10px;
  background-color: "rgba(91, 91, 91, 0.1)";
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
`;

export const ReportButton = styled.button`
  margin-left: 10px;
  background-color: "rgba(91, 91, 91, 0.1)";
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;

  a {
    margin-left: 3px;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;
