import React from 'react';
import { EventsItem, EventsHeader, SportIcon, SportName, EventCountText } from '../UserHome.styled';

export const EventItem = ({ sport, events, icon, iconColor, t }) => (
  <EventsItem key={sport} style={{ textTransform: "Capitalize" }}>
    <EventsHeader>
      <SportIcon className="sport" src={icon} filter={iconColor} />
      <SportName>{t(sport)}</SportName>
    </EventsHeader>
    <EventCountText>{events.length}</EventCountText>
  </EventsItem>
);