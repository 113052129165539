import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useInterval } from "react-use";
import { observer } from "mobx-react-lite";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Spinner from "react-bootstrap/Spinner";
import _groupBy from "lodash/groupBy";

import { NavBarMain, ValuebetEventCard, ValuebetCard, CalculatorCard } from "..";
import {
  UserBetsContainer,
  Container,
  SelectedEvent,
  AllArbsContainer,
  SelectedArbContainer,
  VisitorBadge,
  ReportButton,
  SpinnerWrapper,
  ConfigButton,
} from "./ValuebetsPre.styled";
import { ConfigureValuebetModal } from "../ConfigureValuebetModal/ConfigureValuebetModal";
import { Button, Alert } from "react-bootstrap";
import { useSettings, useEvents, useMemoPrev, useUser, useReport } from "../../hooks";
import { SettingsProvider } from "../../contexts";
import { createValuebetsRepository } from "../../repositories/ValuebetsRepository/Valuebets.repository";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { ReportProvider } from "../../contexts";
import { getSubscriptionsForValuebets, subscriptionName } from "../../utils/subscriptions";

const fpPromise = FingerprintJS.load({ monitoring: false });

const { getValuebetsPre } = createValuebetsRepository();

const useCalculator = () => {
  const [inputReturn, setInputReturn] = useState(200);
  const [calculatorSelectedBookie, setCalculatorSelectedBookie] = useState(undefined);
  const [calculatorEnabled, enableCalculator] = useState(false);
  const [mode, setMode] = useState('normal');

  const getBet1 = (arb) => calculatorSelectedBookie && arb.bets.find(bet => bet.bookie === calculatorSelectedBookie) ? arb.bets.find(bet => bet.bookie === calculatorSelectedBookie) : arb.bets[0];
  const getBet2 = (arb) => calculatorSelectedBookie && arb.bets.find(bet => bet.bookie === calculatorSelectedBookie) ? arb.bets.find(bet => bet.bookie !== calculatorSelectedBookie) : arb.bets[1];

  const getReturnForBet1 = arb => (getBet1(arb).odds * inputReturn) - (mode === 'freebet' ? inputReturn : 0);

  const getStakeForBet2 = arb => getReturnForBet1(arb) / getBet2(arb).odds;

  const getStakeForBet = (arb, { bookie, idx }) => bookie === calculatorSelectedBookie || (!calculatorSelectedBookie && idx === 0) ? inputReturn : getStakeForBet2(arb);

  return { inputReturn, setInputReturn, calculatorSelectedBookie, setCalculatorSelectedBookie, calculatorEnabled, enableCalculator, getBet1, getBet2, getReturnForBet1, getStakeForBet, getStakeForBet2, mode, setMode };
};

// Wrap into provider:
// - configuration provider to have access to configuration
// - report provider to have a single modal for all reports
export const ValuebetsPre = (props) => (
  <SettingsProvider type="valuebetsPre" >
    <ReportProvider>
      <ArbsContent {...props} />
    </ReportProvider>
  </SettingsProvider>
);

const ArbsContent = observer(({ history }) => {
  const { captureSnapshot } = useEvents();
  const { settings } = useSettings();
  const configuration = settings?.configuration;
  const [eventFiltered, setEventState] = useState(undefined);
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const [pinnedValuebetId, setPinnedValuebetId] = useState(undefined);
  const { getActiveSubscription, getExtensionConfiguration } = useUser();
  const [subscriptionStatus, setSubscriptionStatus] = useState(undefined);
  const [filters, setFilters] = useState([]);
  const [visitorId, setVisitorId] = useState(undefined);
  const [tokenConfiguration, setTokenConfiguration] = useState(undefined);
  const [showVisitorId] = useState(false);
  const { openReportModal } = useReport();
  const [valuebets, setValuebets] = useState([]);
  const [areValuebetsLoaded, setAreValuebetsLoaded] = useState(false);

  const calculator = useCalculator();

  const processFilter = useCallback((filter, valuebet) => {
    const filterTypes = {
      1: () => filter.data.id === valuebet.id,
      2: () => filter.data.eventId === valuebet.match.id,
      3: () =>
        filter.data.eventId === valuebet.match.id && [valuebet.bet.bookie].includes(filter.data.bookie),
    };

    return filterTypes[filter.type] ? filterTypes[filter.type]() : false;
  }, []);

  const checkIfNeedsFiltering = useCallback(
    (arb) => !filters.some((filter) => processFilter(filter, arb)),
    [filters, processFilter]
  );

  useEffect(() => {
    calculator.setMode(configuration?.mode);
  }, [configuration?.mode]);

  // A function that sorts arbs depending on the configuration
  const arbsSortFunc = useMemo(() => {
    const sortBy = {
      timeAsc: (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      timeDes: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      profitDes: (a, b) => b.rawProfit - a.rawProfit,
      profitAsc: (a, b) => a.rawProfit - b.rawProfit,
    };

    return sortBy.profitDes;
  }, [configuration?.sortBy, configuration?.mode]);

  // filter arbs using the selected settings configuration
  const filteredValuebets = useMemo(
    () =>
      valuebets
    .filter((valuebet) => {
    //     // Apply filters

        let bookieConfig = configuration.subscriptionConfigs[valuebet.bet.bookie];
        if (bookieConfig === undefined) bookieConfig = configuration;

        if (!checkIfNeedsFiltering(valuebet)) return;

        // Only include selected bookies
        if (!configuration.bookies.some(bookie => bookie.includes(valuebet.bet.bookie))) return;

        // Filter minPercentage
        if (!(valuebet.profit >= (parseInt(bookieConfig.minPercentage) || 0))) return;

        // Filter maxPercentage
        if (!(valuebet.profit <= (parseInt(bookieConfig.maxPercentage) || 100))) return;

        // Filter sports
        if (!configuration.sports.includes(valuebet.match?.sport?.name)) return;

        // Filter sports markets
        if (!configuration.sportsMarkets[valuebet.match?.sport?.name]?.includes(valuebet.bet.marketShortName))
          return;

        // Filter excluded leagues
        if (
          configuration.excludedLeagues?.some((league) =>
            valuebet.match.league.name.toLowerCase().includes(league.toLowerCase())
          )
        )
          return;

          const oddsInsideFilter = (bookieConfig.minOdds <= valuebet.bet.odds && bookieConfig.maxOdds >= valuebet.bet.odds);
        if(!oddsInsideFilter) return;

        // If all filters passed, keep the arb
        return true;
      })
    ,
    [valuebets, checkIfNeedsFiltering, eventFiltered, configuration]
  );

  const pinnedValuebet = useMemoPrev((prevpinnedValuebet) => {
    if (pinnedValuebetId === undefined) return undefined;
    let valuebet = valuebets.find(valuebet => valuebet.id === pinnedValuebetId);
    if (!valuebet && prevpinnedValuebet) return {...prevpinnedValuebet, deleted: true};
    return valuebet;
  }, [pinnedValuebetId, valuebets])

  const setPinnedValuebet = useCallback((valuebet) => {
    calculator.setCalculatorSelectedBookie(undefined);
    return setPinnedValuebetId(valuebet?.id)
  }, [setPinnedValuebetId]);

  // array of matches obtained from grouping all arbs by its match id
  const matches = useMemo(() => {
    const matchesHash = _groupBy(filteredValuebets, (valuebet) => valuebet.match.id);
    const _matches = Object.entries(matchesHash).map(([matchId, matchValuebets]) => ({
      id: parseInt(matchId),
      valuebets: matchValuebets.sort(arbsSortFunc),
    }));
    return _matches.filter(({ id }) => (eventFiltered ? eventFiltered.id === id : true)).sort((matchA, matchB) => arbsSortFunc(matchA.valuebets[0], matchB.valuebets[0]));
  }, [filteredValuebets]);

  const matchesForpinnedValuebet = useMemo(
    () => (pinnedValuebet ? matches.filter((match) => match.id === pinnedValuebet.match.id) : []),
    [matches, pinnedValuebet]
  );

  const createReport = useCallback(async (event) => {
    event.stopPropagation();
    openReportModal();
  }, [openReportModal]);

  const setEvent = useCallback((event) => {
    if (!eventFiltered) setEventState(event);
  }, []);

  const addFilter = useCallback((filter) => setFilters((previousFilters) => [...previousFilters, filter]), []);

  // Inital stuff to do after first render
  useEffect(() => {
    checkIfTokenStillValid(history);

    // Use a function to use async/await syntax inside useEffect
    const onMounted = async () => {
      await Promise.all([
        getActiveSubscription().then((subscriptionStatus) => setSubscriptionStatus(subscriptionStatus)),
        fpPromise.then((fp) => fp.get()).then((result) => setVisitorId(result.visitorId)),
        getExtensionConfiguration().then((response) => setTokenConfiguration(response)),
      ]);
    };
    onMounted();

  }, []);

  useEffect(() => {
    let timeoutId;
    let stopped = false;

    const processUpdates = () => {
      // Periodically get new arbs
        // Do nothing until settings are loaded
        if (!settings) return;
    
        const { configuration } = settings;
        const { minPercentage, maxPercentage, minOdds, maxOdds } = configuration;
        const channels = getSubscriptionsForValuebets(configuration);
        const fn = getValuebetsPre;

        fn({ channels, minPercentage, maxPercentage, minOdds, maxOdds }).then(newValuebets => {
          setAreValuebetsLoaded(true);
          setValuebets(newValuebets);
          if (!stopped) timeoutId = window.setTimeout(processUpdates, 2000);
        });
      };
    
      processUpdates();

      return () => {
        stopped = true;
        window.clearTimeout(timeoutId);
      };
  }, [settings]); 

  return (
    <>
      <UserBetsContainer>
      <NavBarMain currentPage="pre" type="valuebetsPre" history={history}/>
        {configuration?.bookiesReference?.length === 0 && (
          <Alert variant="danger" className="customAlert">
            <p>No tienes ninguna bookie de referencia seleccionada. ¡Modifica tu configuración!</p>
          </Alert>
        )}
        <rn-banner />
        <div style={{ display: "flex", alignItems: "center", marginTop: '20px' }}>
          <ConfigureValuebetModal show={showConfigurationModal} setVisible={setShowConfigurationModal} />
          <ConfigButton disabled={!configuration} onClick={() => setShowConfigurationModal(true)}>
            Configurar
          </ConfigButton>
          <ReportButton onClick={createReport}>
            <span className="fas fa-exclamation-triangle"></span>
            <a>Reportar</a>
          </ReportButton>
          {showVisitorId && <VisitorBadge>{visitorId}</VisitorBadge>}
        </div>
        {configuration &&
          (!areValuebetsLoaded ? (
            <SpinnerWrapper>
              <Spinner animation="border" role="loading" />
            </SpinnerWrapper>
          ) : (
            <>
              {eventFiltered && (
                <SelectedEvent>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    style={{ float: "left", marginRight: "5px" }}
                    onClick={() => setEventState(undefined)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  Evento seleccionado:
                  <span className="badge badge-secondary" style={{ fontSize: "medium" }}>
                    {eventFiltered.name}
                  </span>
                </SelectedEvent>
              )}
              <Container>
                <AllArbsContainer pinnedValuebet={!!pinnedValuebet}>
                  {matches.map((match) => (
                    <ValuebetEventCard
                      key={match.id}
                      configuration={configuration}
                      valuebets={match.valuebets}
                      event={match.valuebets[0].event}
                      captureSnapshot={captureSnapshot}
                      setEvent={setEvent}
                      setPinnedValuebet={setPinnedValuebet}
                      eventFiltered={eventFiltered}
                      pre={true}
                    />
                  ))}
                </AllArbsContainer>
                {pinnedValuebet && (
                  <SelectedArbContainer pinnedValuebet={!!pinnedValuebet}>
                    <ValuebetCard
                      valuebet={pinnedValuebet}
                      event={pinnedValuebet.event}
                      captureSnapshot={captureSnapshot}
                      pinned={true}
                      setPinnedValuebet={setPinnedValuebet}
                      addFilter={addFilter}
                      calculator={calculator}
                      tokenConfiguration={tokenConfiguration}
                      pre={true}
                    />
                    <CalculatorCard
                      valuebet={pinnedValuebet}
                      event={pinnedValuebet.event}
                      pinned={true}
                      calculator={calculator}
                    />
                    {matchesForpinnedValuebet.map((match) => (
                    <ValuebetEventCard
                        key={match.id}
                        configuration={configuration}
                        valuebets={match.valuebets}
                        event={match.valuebets[0].event}
                        captureSnapshot={captureSnapshot}
                        setEvent={setEvent}
                        setPinnedValuebet={setPinnedValuebet}
                        eventFiltered={true}
                        pre={true}
                      />
                    ))}
                  </SelectedArbContainer>
                )}
              </Container>
            </>
          ))}
      </UserBetsContainer>
    </>
  );
});
