import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useTranslation } from 'react-i18next';
import { NavBarMain } from "..";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { useUser, useEvents } from "../../hooks";
import { formatCurrency, getFormattedTime } from "../../utils/formatting";
import { EventsSection } from "./components/EventsSection";
import { SubscriptionSection } from "./components/SubscriptionSection";
import { PurchaseSection } from "./components/PurchaseSection";
import useSubscription from "./hooks/useSubscription";
import { PageContainer, ContentArea } from './UserHome.styled';

export const UserHome = ({ history }) => {
  const { t } = useTranslation();
  const { getEvents, getUpcomingEvents } = useEvents();
  const { getInvoices } = useUser();
  const [events, setEvents] = useState({});
  const [upcomingEvents, setUpcomingEvents] = useState({});
  const [invoices, setInvoices] = useState([]);

  const {
    subscriptionStatus,
    availableSubscriptions,
    selectedSubscription,
    handleSelectedSubscription,
    handleSubscriptionAction,
    isSubscriptionNonExisting,
    applyCoupon,
    couponError,
  } = useSubscription();

  useEffect(() => {
    const fetchData = async () => {
      checkIfTokenStillValid(history);
      const [fetchedEvents, fetchedUpcomingEvents, fetchedInvoices] = await Promise.all([
        getEvents(),
        getUpcomingEvents(),
        getInvoices(),
      ]);
      setEvents(fetchedEvents);
      setUpcomingEvents(fetchedUpcomingEvents);
      setInvoices(fetchedInvoices);
    };
    fetchData();
  }, []);

  return (
    <PageContainer>
      <NavBarMain currentPage="home" history={history}/>
      <ContentArea>
        <EventsSection 
          events={events} 
          upcomingEvents={upcomingEvents} 
          t={t} 
        />
        {isSubscriptionNonExisting ? (
          <PurchaseSection
            selectedSubscription={selectedSubscription}
            availableSubscriptions={availableSubscriptions}
            handleSelectedSubscription={handleSelectedSubscription}
            handleSubscriptionAction={handleSubscriptionAction}
            t={t}
            applyCoupon={applyCoupon}
            couponError={couponError}
          />
        ) : (
          <SubscriptionSection
            subscriptionStatus={subscriptionStatus}
            handleSubscriptionAction={handleSubscriptionAction}
            t={t}
          />
        )}
      </ContentArea>
    </PageContainer>
  );
};