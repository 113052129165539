import React, { useEffect, useState } from "react";
import { NavBarMain, Bookie } from "..";
import { useUser } from "../../hooks";
import {
  BookieContainer, BookieHeaderText, BookiesSubContainer,
  BookiesHeaderContainer, BookiesListContainer, PageContainer
} from './UserBookies.styled'
import { checkIfTokenStillValid } from "../../repositories/utils";
import Calculator from "./Calculator";
import { useTranslation } from "react-i18next";

export const UserBookies = ({history, match}) => {
    const { t } = useTranslation();
    const { bookieAction, getBookies } = useUser()
    const [activeBookies, setActiveBookies] = useState({});
    const [bookies, setBookies] = useState(null);
    const [activeTab, setActiveTab] = useState("available");

    useEffect(() => {
      getBookies(match.params.email, false).then(res => setBookies(res))
      checkIfTokenStillValid(history)
    }, [])

    const setBookieStatus = (bookie, enabledStatus) => {
      setActiveBookies({...activeBookies, [bookie]: enabledStatus});
    };

    const toggleBookieStatus = async (bookie, userEnabled) => {
      const action = userEnabled ? "disable" : "enable";
      await bookieAction(match.params.email, bookie, action)
      const newStatus = action === "enable";
      setBookieStatus(bookie, newStatus);
    };

    const getAvailableBookies = () => bookies && bookies.filter((bookie) => !bookie.inUse);

    const getUsedBookies = () => bookies?.filter((bookie) => bookie.inUse);

    const renderTabContent = () => {
      switch(activeTab) {
        case "available":
          return (
            <BookieContainer>
              <BookiesSubContainer>
                <BookiesHeaderContainer>
                  <BookieHeaderText>{t("availableBookies")}</BookieHeaderText>
                </BookiesHeaderContainer>
                <BookiesListContainer>
                  {getAvailableBookies()?.map((bookie) => (
                    <Bookie
                      key={bookie.id}
                      bookies={bookies}
                      bookie={bookie}
                      toggleBookieStatus={toggleBookieStatus}
                      activeBookies={activeBookies}
                      match={match}
                    />
                  ))}
                </BookiesListContainer>
              </BookiesSubContainer>
            </BookieContainer>
          );
        case "inUse":
          return (
            <BookieContainer>
              <BookiesSubContainer>
                <BookiesHeaderContainer>
                  <BookieHeaderText>{t("bookiesInUse")}</BookieHeaderText>
                </BookiesHeaderContainer>
                <BookiesListContainer>
                  {getUsedBookies()?.map((bookie) => (
                    <Bookie
                      key={bookie.id}
                      bookies={bookies}
                      bookie={bookie}
                      type="edit"
                      toggleBookieStatus={toggleBookieStatus}
                      activeBookies={activeBookies}
                      match={match}
                    />
                  ))}
                </BookiesListContainer>
              </BookiesSubContainer>
            </BookieContainer>
          );
        case "calculator":
          return <Calculator />;
        default:
          return null;
      }
    };

    return (
      <PageContainer>
        <NavBarMain currentPage="bothome" history={history}/>
        <div className="w-full mt-6">
          <div className="flex justify-center mb-4">
            {["available", "inUse", "calculator"].map((tab) => (
              <button
                key={tab}
                className={`sm:px-4 px-2 py-2 mx-2 rounded-md ${
                  activeTab === tab
                    ? "bg-[#E8FC8B] text-[#1B2D1A] font-bold"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300 transition-colors duration-200"
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {t(tab === "available" ? "availableBookies" : tab === "inUse" ? "bookiesInUse" : "calculator")}
              </button>
            ))}
          </div>
          
          {renderTabContent()}
        </div>
      </PageContainer>
    );
  }
;
