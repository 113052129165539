import React from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from 'react-i18next';

const termsByLanguage = {
  en: `
<h1 id="terms-and-conditions-of-betsmarterapp">Terms and Conditions of <strong>betsmarter.app</strong></h1>
<p>These Terms govern</p>
<ul>
<li>the use of betsmarter.app, and,</li>
<li>any other related Agreement or legal relationship with the Owner</li>
</ul>
<p>in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</p>
<p>The User must read this document carefully.</p>
<p>Betsmarter.app is provided by:</p>
<p>BetSmarter OÜ - Harju maakond, Tallinn, Lasnamäe linnaosa, Lõõtsa tn 5, 11415</p>
<p><strong>Owner contact email:</strong> <a href="mailto:&#x69;&#110;&#102;&#111;&#64;&#98;&#101;&#x74;&#115;&#109;&#x61;&#114;&#x74;&#101;&#114;&#x2e;&#97;&#112;&#x70;">&#x69;&#110;&#102;&#111;&#64;&#98;&#101;&#x74;&#115;&#109;&#x61;&#114;&#x74;&#101;&#114;&#x2e;&#97;&#112;&#x70;</a></p>
<h1 id="information-about-betsmarterapp"><strong>Information about betsmarter.app</strong></h1>
<ol>
<li>The service provides the User with information, which is compiled by analyzing data and odds of bookmakers.</li>
<li>The service is not a financial tool and does not provide services for managing funds from third parties.<strong>The service is not a bookmaker or a gamble website and does not organize or conduct gambling.</strong></li>
</ol>
<p>&quot;Betsmarter.app&quot; refers to</p>
<ul>
<li>this website, including its subdomains and any other website through which the Owner makes its Service available;</li>
<li>the Application Program Interfaces (API);</li>
</ul>
<h1 id="what-the-user-should-know-at-a-glance"><strong>What the User should know at a glance</strong></h1>
<ul>
<li>The Service/betsmarter.app is only intended for Consumers.</li>
<li><strong>Usage of betsmarter.app and the Service is age restricted</strong>: to access and use betsmarter.app and its Service the User must be an adult under applicable law.</li>
<li>The right of withdrawal only applies to European Consumers.</li>
</ul>
<hr>
<h1 id="terms-of-use"><strong>TERMS OF USE</strong></h1>
<p>Unless otherwise specified, the terms of use detailed in this section apply generally when using betsmarter.app.</p>
<p>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>
<p>By using betsmarter.app, Users confirm to meet the following requirements:</p>
<ul>
<li>Users must qualify as Consumers;</li>
<li>Users must be recognized as adult by applicable law;</li>
</ul>
<h3 id="account-registration"><strong>Account registration</strong></h3>
<p>To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner.</p>
<p>Failure to do so will cause unavailability of the Service.</p>
<p>Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by betsmarter.app.</p>
<p>By registering, Users agree to be fully responsible for all activities that occur under their username and password.</p>
<p>Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</p>
<h3 id="conditions-for-account-registration"><strong>Conditions for account registration</strong></h3>
<p>Registration of User accounts on betsmarter.app is subject to the conditions outlined below. By registering, Users agree to meet such conditions.</p>
<ul>
<li>Accounts registered by bots or any other automated methods are not permitted.</li>
<li>Unless otherwise specified, each User must register only one account.</li>
<li>Unless explicitly permitted, a User account may not be shared with other persons.</li>
</ul>
<h3 id="account-termination"><strong>Account termination</strong></h3>
<p>Users can terminate their account and stop using the Service at any time by doing the following:</p>
<ul>
<li>By directly contacting the Owner at the contact details provided in this document.</li>
</ul>
<h3 id="account-suspension-and-deletion"><strong>Account suspension and deletion</strong></h3>
<p>The Owner reserves the right to suspend or terminate the User&#39;s account at any time and without notice, at the Owner&#39;s sole discretion, in these cases:</p>
<ul>
<li>User has violated these Terms; and/or</li>
<li>User&#39;s access or use of betsmarter.app may cause injury to the Owner, other Users or third parties; and/or</li>
<li>the use of betsmarter.app by the User may cause violation of law or regulations; and/or</li>
<li>in case of an investigation by legal action or governmental involvement; and/or</li>
<li>the account or its use is deemed to be, at the Owner’s sole discretion inappropriate or offensive or in violation of these Terms.</li>
</ul>
<p>The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.</p>
<p>The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.</p>
<h3 id="content-on-betsmarterapp"><strong>Content on betsmarter.app</strong></h3>
<p>Unless where otherwise specified or clearly recognizable, all content available on betsmarter.app is owned or provided by the Owner or its licensors.</p>
<p>The Owner undertakes its utmost effort to ensure that the content provided on betsmarter.app infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.</p>
<p>In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
<h3 id="rights-regarding-content-on-betsmarterapp---all-rights-reserved"><strong>Rights regarding content on betsmarter.app - All rights reserved</strong></h3>
<p>The Owner holds and reserves all intellectual property rights for any such content.</p>
<p>Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.</p>
<p>In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on betsmarter.app, nor allow any third party to do so through the User or their device, even without the User&#39;s knowledge.</p>
<p>Where explicitly stated on betsmarter.app, the User may download, copy and/or share some content available through betsmarter.app for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.</p>
<p>Any applicable statutory limitation or exception to copyright shall stay unaffected.</p>
<h3 id="access-to-external-resources"><strong>Access to external resources</strong></h3>
<p>Through betsmarter.app Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>
<p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.</p>
<h3 id="acceptable-use"><strong>Acceptable use</strong></h3>
<p>Betsmarter.app and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>
<p>Users are solely responsible for making sure that their use of betsmarter.app and/or the Service violates no applicable law, regulations or third-party rights.</p>
<p>Therefore,<strong>the Owner reserves the right to take any appropriate measure to protect its legitimate interests including denying Users access to betsmarter.app or the Service, terminating contracts, reporting any misconduct performed through betsmarter.app or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users are suspected to be in violation of any laws, regulations, third-party rights and/or these Terms, including, but not limited to, by engaging in any of the following activities:</strong></p>
<h3 id="conduct-restrictions"><strong>Conduct restrictions</strong></h3>
<ul>
<li>pretending to fulfill any possible condition or requirements for accessing betsmarter.app and/or using the Services, such as for instance being adult according to law or qualifying as a Consumer;</li>
<li>concealing their identity or stealing someone else&#39;s identity or pretend to be or represent a third-party, if not allowed to do so by such third-party;</li>
<li>manipulating identifiers to disguise or otherwise conceal the origin of their messages or of the content posted;</li>
<li>defaming, abusing, harassing, using threatening practices, threatening or violating the legal rights of others in any other way;</li>
<li>promoting activity that may endanger the User’s life or the life of any other User or lead to physical harm. This includes but is not limited to suicide threats or instigations, intentional physical trauma, the use of illegal drugs, or excessive drinking. Under no circumstance is any User allowed to post any content promoting and/or encouraging and/or showing any self-destructive or violent behavior on betsmarter.app;</li>
<li>probing, scanning or testing the vulnerability of betsmarter.app, including the services or any network connected to the website, nor breaching the security or authentication measures on betsmarter.app, including the services or any network connected to betsmarter.app;</li>
<li>installing, embedding, uploading or otherwise incorporating any malware into or via betsmarter.app;</li>
<li>using betsmarter.app or the technical infrastructure in an abusive, excessive or otherwise inappropriate way (for example: for spamming purposes);</li>
<li>attempting to disrupt or tamper with the technical infrastructure in a manner that harms or places an undue burden on betsmarter.app or the Service;</li>
</ul>
<h3 id="scraping"><strong>Scraping</strong></h3>
<ul>
<li>adopting any automated process to extract, harvest or scrape information, data and/or content from betsmarter.app and all the digital properties thereto related unless where explicitly allowed to do so by the Owner;</li>
</ul>
<h3 id="content-restrictions"><strong>Content restrictions</strong></h3>
<ul>
<li>disseminating or publishing content that is unlawful, obscene, illegitimate, libelous or inappropriate;</li>
<li>publishing any content that promotes, either directly or indirectly, hate, racism, discrimination, pornography, violence;</li>
<li>disseminating or publishing any content that is false or may create unjustified alarm;</li>
<li>using betsmarter.app to publish, disseminate or otherwise provide content protected by intellectual property laws, including but not limited to patent, trademark or copyright law, unlawfully and without the legitimate right-holder’s consent;</li>
<li>using betsmarter.app to publish, disseminate or otherwise make available any other content which infringes on any third-party rights, including but not limited to state, military, trade or professional secrets and personal data;</li>
<li>publishing any content or carrying out any activity that disrupts, interrupts, harms, or otherwise violates the integrity of betsmarter.app or another User&#39;s experience or devices. Such activities include: spamming, distributing unauthorized advertisements, phishing, defrauding others, spreading malware or viruses etc.;</li>
</ul>
<h3 id="tell-a-friend"><strong>“Tell-a-friend”</strong></h3>
<p>Betsmarter.app gives Users the opportunity to receive advantages if, as a result of their recommendation, any new User purchases a Product offered on betsmarter.app.</p>
<p>In order to take advantage of this offer, Users may invite others to purchase the Products on betsmarter.app by sending them a tell-a-friend code provided by the Owner. Such codes can only be redeemed once.</p>
<p>If upon purchase of the Products on betsmarter.app any of the persons invited redeems a tell-a-friend code, the inviting User shall receive the advantage or benefit (such as: a price reduction, an additional service feature, an upgrade etc.) specified on betsmarter.app.</p>
<p>Tell-a-friend codes may be limited to specific Products among those offered on betsmarter.app.</p>
<p>The Owner reserves the right to end the offer at any time at its own discretion.</p>
<p>While no general limitation applies to the number of persons that can be invited, the amount of advantage or benefit that each inviting User can receive, may be limited.</p>
<h3 id="api-usage-terms"><strong>API usage terms</strong></h3>
<p>Users may access their data relating to betsmarter.app via the Application Program Interface (API). Any use of the API, including use of the API through a third-party product/service that accesses betsmarter.app, is bound by these Terms and, in addition, by the following specific terms:</p>
<ul>
<li>the User expressly understands and agrees that the Owner bears no responsibility and shall not be held liable for any damages or losses resulting from the User’s use of the API or their use of any third-party products/services that access data through the API.</li>
</ul>
<h1 id="terms-and-conditions-of-sale"><strong>TERMS AND CONDITIONS OF SALE</strong></h1>
<h3 id="paid-products"><strong>Paid Products</strong></h3>
<p>Some of the Products provided on betsmarter.app, as part of the Service, are provided on the basis of payment.</p>
<p>The fees, duration and conditions applicable to the purchase of such Products are described below and in the dedicated sections of betsmarter.app.</p>
<p>To purchase Products, the User must register or log into betsmarter.app.</p>
<h3 id="product-description"><strong>Product description</strong></h3>
<p>Prices, descriptions or availability of Products are outlined in the respective sections of betsmarter.app and are subject to change without notice.</p>
<p>While Products on betsmarter.app are presented with the greatest accuracy technically possible, representation on betsmarter.app through any means (including, as the case may be, graphic material, images, colors, sounds) is for reference only and implies no warranty as to the characteristics of the purchased Product.</p>
<p>The characteristics of the chosen Product will be outlined during the purchasing process.</p>
<h3 id="purchasing-process"><strong>Purchasing process</strong></h3>
<p>Any steps taken from choosing a Product to order submission form part of the purchasing process.</p>
<p>The purchasing process includes these steps:</p>
<ul>
<li>Users must choose the desired Product and verify their purchase selection.</li>
<li>After having reviewed the information displayed in the purchase selection, Users may place the order by submitting it.</li>
</ul>
<h3 id="order-submission"><strong>Order submission</strong></h3>
<p>When the User submits an order, the following applies:</p>
<ul>
<li>The submission of an order determines contract conclusion and therefore creates for the User the obligation to pay the price, taxes and possible further fees and expenses, as specified on the order page.</li>
<li>In case the purchased Product requires an action from the User, such as the provision of personal information or data, specifications or special wishes, the order submission creates an obligation for the User to cooperate accordingly.</li>
<li>Upon submission of the order, Users will receive a receipt confirming that the order has been received.</li>
</ul>
<p>All notifications related to the described purchasing process shall be sent to the email address provided by the User for such purposes.</p>
<h3 id="prices"><strong>Prices</strong></h3>
<p>Users are informed during the purchasing process and before order submission, about any fees, taxes and costs (including, if any, delivery costs) that they will be charged.</p>
<p>Prices on betsmarter.app are displayed:</p>
<ul>
<li>including all applicable fees, taxes and costs.</li>
</ul>
<h3 id="methods-of-payment"><strong>Methods of payment</strong></h3>
<p>Information related to accepted payment methods are made available during the purchasing process.</p>
<p>Some payment methods may only be available subject to additional conditions or fees. In such cases related information can be found in the dedicated section of betsmarter.app.</p>
<p>All payments are independently processed through third-party services. Therefore, betsmarter.app does not collect any payment information – such as credit card details – but only receives a notification once the payment has been successfully completed.</p>
<p>If a payment through the available methods fails or is refused by the payment service provider, the Owner shall be under no obligation to fulfill the purchase order. If a payment fails or is refused, the Owner reserves the right to claim any related expenses or damages from the User.</p>
<h3 id="authorization-for-future-paypal-payment"><strong>Authorization for future PayPal payment</strong></h3>
<p>If Users authorize the PayPal feature which allows future purchases, betsmarter.app will store an identification code linked to the Users’ PayPal account. This will authorize betsmarter.app to automatically process payments for future purchases or recurring installments of past purchases.</p>
<p>This authorization can be revoked at any time, either by contacting the Owner or by changing the user settings offered by PayPal.</p>
<h3 id="retention-of-usage-rights"><strong>Retention of usage rights</strong></h3>
<p>Users do not acquire any rights to use the purchased Product until the total purchase price is received by the Owner.</p>
<h3 id="delivery"><strong>Delivery</strong></h3>
<h3 id="performance-of-services"><strong>Performance of services</strong></h3>
<p>The purchased service shall be performed or made available within the timeframe specified on betsmarter.app or as communicated before the order submission.</p>
<h3 id="contract-duration"><strong>Contract duration</strong></h3>
<h3 id="subscriptions"><strong>Subscriptions</strong></h3>
<p>Subscriptions allow Users to receive a Product continuously or regularly over time. Details regarding the type of subscription and termination are outlined below.</p>
<h3 id="fixed-term-subscriptions"><strong>Fixed-term subscriptions</strong></h3>
<p>Paid fixed-term subscriptions start on the day the payment is received by the Owner and last for the subscription period chosen by the User or otherwise specified during the purchasing process.</p>
<p>Once the subscription period expires, the Product shall no longer be accessible.</p>
<h1 id="user-rights"><strong>User rights</strong></h1>
<h3 id="right-of-withdrawal"><strong>Right of withdrawal</strong></h3>
<p>Unless exceptions apply, the User may be eligible to withdraw from the contract within the period specified below (generally 14 days), for any reason and without justification. Users can learn more about the withdrawal conditions within this section.</p>
<h3 id="who-the-right-of-withdrawal-applies-to"><strong>Who the right of withdrawal applies to</strong></h3>
<p>Unless any applicable exception is mentioned below, Users who are European Consumers are granted a statutory cancellation right under EU rules, to withdraw from contracts entered into online (distance contracts) within the specified period applicable to their case, for any reason and without justification.</p>
<p>Users that do not fit this qualification, cannot benefit from the rights described in this section.</p>
<h3 id="exercising-the-right-of-withdrawal"><strong>Exercising the right of withdrawal</strong></h3>
<p>To exercise their right of withdrawal, Users must send to the Owner an unequivocal statement of their intention to withdraw from the contract.</p>
<p>To this end, Users may use the model withdrawal form available from within the “definitions” section of this document. Users are, however, free to express their intention to withdraw from the contract by making an unequivocal statement in any other suitable way. In order to meet the deadline within which they can exercise such right, Users must send the withdrawal notice before the withdrawal period expires.</p>
<p>When does the withdrawal period expire?</p>
<ul>
<li><strong>Regarding the purchase of a service</strong>, the withdrawal period expires 14 days after the day that the contract is entered into, unless the User has waived the withdrawal right.</li>
</ul>
<h3 id="effects-of-withdrawal"><strong>Effects of withdrawal</strong></h3>
<p>Users who correctly withdraw from a contract will be reimbursed by the Owner for all payments made to the Owner, including, if any, those covering the costs of delivery.</p>
<p>However, any additional costs resulting from the choice of a particular delivery method other than the least expensive type of standard delivery offered by the Owner, will not be reimbursed.</p>
<p>Such reimbursement shall be made without undue delay and, in any event, no later than 14 days from the day on which the Owner is informed of the User’s decision to withdraw from the contract. Unless otherwise agreed with the User, reimbursements will be made using the same means of payment as used to process the initial transaction. In any event, the User shall not incur any costs or fees as a result of such reimbursement.</p>
<h3 id="on-the-purchase-of-services"><strong>…on the purchase of services</strong></h3>
<p>Where a User exercises the right of withdrawal after having requested that the service be performed before the withdrawal period expires, the User shall pay to the Owner an amount which is in proportion to the part of service provided.</p>
<p>Such payment shall be calculated based on the fee contractually agreed upon, and be proportional to the part of service provided until the time the User withdraws, compared with the full coverage of the contract.</p>
<h1 id="liability-and-indemnification"><strong>Liability and indemnification</strong></h1>
<h3 id="indemnification"><strong>Indemnification</strong></h3>
<p>The User agrees to indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners and employees harmless from and against any claim or demand ⁠— including but not limited to lawyer&#39;s fees and costs ⁠— made by any third party due to or in relation with any culpable use of or connection to the Service, violation of these Terms, infringement of any third-party rights or statutory provision by the User or its affiliates, officers, directors, agents, co-branders, partners and employees to the extent allowed by applicable law.</p>
<h3 id="limitation-of-liability"><strong>Limitation of liability</strong></h3>
<p>Unless otherwise explicitly stated and without prejudice to applicable statutory product liability provisions, Users shall have no right to claim damages against the Owner (or any natural or legal person acting on its behalf).</p>
<p>This does not apply to damages to life, health or physical integrity, damages resulting from the breach of an essential contractual obligation such as any obligation strictly necessary to achieve the purpose of the contract, and/or damages resulting from intent or gross negligence, as long as betsmarter.app has been appropriately and correctly used by the User.</p>
<p>Unless damages have been caused by way of intent or gross negligence, or they affect life, health or physical integrity, the Owner shall only be liable to the extent of typical and foreseeable damages at the moment the contract was entered into.</p>
<h1 id="common-provisions"><strong>Common provisions</strong></h1>
<h3 id="no-waiver"><strong>No Waiver</strong></h3>
<p>The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
<h3 id="service-interruption"><strong>Service interruption</strong></h3>
<p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>
<p>Within the limits of law, the Owner may also decide to suspend or discontinue the Service altogether. If the Service is discontinued, the Owner will cooperate with Users to enable them to withdraw Personal Data or information and will respect Users&#39; rights relating to continued product use and/or compensation, as provided for by applicable law.</p>
<p>Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” events( infrastructural breakdowns or blackouts etc.).</p>
<h3 id="service-reselling"><strong>Service reselling</strong></h3>
<p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of betsmarter.app and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.</p>
<h3 id="privacy-policy"><strong>Privacy policy</strong></h3>
<p>To learn more about the use of their Personal Data, Users may refer to the privacy policy of betsmarter.app.</p>
<h3 id="intellectual-property-rights"><strong>Intellectual property rights</strong></h3>
<p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to betsmarter.app are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>
<p>All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with betsmarter.app are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
<h3 id="changes-to-these-terms"><strong>Changes to these Terms</strong></h3>
<p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</p>
<p>Such changes will only affect the relationship with the User from the date communicated to Users onwards.</p>
<p>The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service and may terminate the Agreement.</p>
<p>The applicable previous version will govern the relationship prior to the User&#39;s acceptance. The User can obtain any previous version from the Owner.</p>
<p>If legally required, the Owner will notify Users in advance of when the modified Terms will take effect.</p>
<h3 id="assignment-of-contract"><strong>Assignment of contract</strong></h3>
<p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account. Provisions regarding changes of these Terms will apply accordingly.</p>
<p>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
<h3 id="contacts"><strong>Contacts</strong></h3>
<p>All communications relating to the use of betsmarter.app must be sent using the contact information stated in this document.</p>
<h3 id="severability"><strong>Severability</strong></h3>
<p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
<h3 id="us-users"><strong>US Users</strong></h3>
<p>Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent. These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter. These Terms will be enforced to the fullest extent permitted by law.</p>
<h3 id="eu-users"><strong>EU Users</strong></h3>
<p>Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.</p>
<p>In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.</p>
<p>Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.</p>
<h3 id="governing-law"><strong>Governing law</strong></h3>
<p>These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.</p>
<h3 id="prevalence-of-national-law"><strong>Prevalence of national law</strong></h3>
<p>However, regardless of the above, if the law of the country that the User is located in provides for a higher applicable consumer protection standard, such higher standards shall prevail.</p>
<h3 id="venue-of-jurisdiction"><strong>Venue of jurisdiction</strong></h3>
<p>The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.</p>
<h3 id="exception-for-consumers-in-europe"><strong>Exception for Consumers in Europe</strong></h3>
<p>The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in the United Kingdom, Switzerland, Norway or Iceland.</p>
<h1 id="dispute-resolution"><strong>Dispute resolution</strong></h1>
<h3 id="amicable-dispute-resolution"><strong>Amicable dispute resolution</strong></h3>
<p>Users may bring any disputes to the Owner who will try to resolve them amicably.</p>
<p>While Users&#39; right to take legal action shall always remain unaffected, in the event of any controversy regarding the use of betsmarter.app or the Service, Users are kindly asked to contact the Owner at the contact details provided in this document.</p>
<p>The User may submit the complaint including a brief description and if applicable, the details of the related order, purchase, or account, to the Owner’s email address specified in this document.</p>
<p>The Owner will process the complaint without undue delay and within 21 days of receiving it.</p>
<h3 id="online-dispute-resolution-for-consumers"><strong>Online dispute resolution for Consumers</strong></h3>
<p>The European Commission has established an online platform for alternative dispute resolutions that facilitates an out-of-court method for solving disputes related to and stemming from online sale and service contracts.</p>
<p>As a result, any European Consumer or Consumer based in Norway, Iceland, or Liechtenstein can use such platform for resolving disputes stemming from contracts which have been entered into online. The platform is <a href="http://ec.europa.eu/consumers/odr/">available at the following link</a>.</p>
<h2 id="definitions-and-legal-references"><strong>Definitions and legal references</strong></h2>
<p>Latest update: June 19, 2023</p>
`,
  es: `
  <h1 id="terminos-y-condiciones-de-betsmarterapp">Términos y Condiciones de <strong>betsmarter.app</strong></h1>
<p>Estos Términos regulan</p>
<ul>
<li>el uso de betsmarter.app, y,</li>
<li>cualquier otro Acuerdo o relación legal relacionada con el Propietario</li>
</ul>
<p>de una manera legalmente vinculante. Las palabras en mayúscula están definidas en la sección correspondiente de este documento.</p>
<p>El Usuario debe leer este documento detenidamente.</p>
<p>Betsmarter.app es proporcionado por:</p>
<p>BetSmarter OÜ - Harju maakond, Tallinn, Lasnamäe linnaosa, Lõõtsa tn 5, 11415</p>
<p><strong>Correo electrónico de contacto del Propietario:</strong> <a href="mailto:&#x69;&#110;&#102;&#111;&#64;&#98;&#101;&#x74;&#115;&#109;&#x61;&#114;&#x74;&#101;&#114;&#x2e;&#97;&#112;&#x70;">&#x69;&#110;&#102;&#111;&#64;&#98;&#101;&#x74;&#115;&#109;&#x61;&#114;&#x74;&#101;&#114;&#x2e;&#97;&#112;&#x70;</a></p>
<h1 id="informacion-sobre-betsmarterapp"><strong>Información sobre betsmarter.app</strong></h1>
<ol>
<li>El servicio proporciona al Usuario información, la cual es compilada mediante el análisis de datos y cuotas de casas de apuestas.</li>
<li>El servicio no es una herramienta financiera y no proporciona servicios para la gestión de fondos de terceros. <strong>El servicio no es una casa de apuestas ni un sitio de juegos de azar, y no organiza ni realiza apuestas.</strong></li>
</ol>
<p>"Betsmarter.app" se refiere a</p>
<ul>
<li>este sitio web, incluidos sus subdominios y cualquier otro sitio web a través del cual el Propietario pone a disposición su Servicio;</li>
<li>las Interfaces de Programación de Aplicaciones (API);</li>
</ul>
<h1 id="lo-que-el-usuario-debe-saber-a-simple-vista"><strong>Lo que el Usuario debe saber a simple vista</strong></h1>
<ul>
<li>El Servicio/betsmarter.app está destinado únicamente a Consumidores.</li>
<li><strong>El uso de betsmarter.app y del Servicio está restringido por edad</strong>: para acceder y utilizar betsmarter.app y su Servicio, el Usuario debe ser mayor de edad según la ley aplicable.</li>
<li>El derecho de desistimiento solo aplica a Consumidores europeos.</li>
</ul>
<hr>
<h1 id="terminos-de-uso"><strong>TÉRMINOS DE USO</strong></h1>
<p>Salvo que se indique lo contrario, los términos de uso detallados en esta sección se aplican de manera general al usar betsmarter.app.</p>
<p>En escenarios específicos, pueden aplicarse condiciones individuales o adicionales de uso o acceso, las cuales se indican además en este documento.</p>
<p>Al usar betsmarter.app, los Usuarios confirman cumplir con los siguientes requisitos:</p>
<ul>
<li>Los Usuarios deben calificar como Consumidores;</li>
<li>Los Usuarios deben ser reconocidos como adultos según la ley aplicable;</li>
</ul>
<h3 id="registro-de-cuenta"><strong>Registro de cuenta</strong></h3>
<p>Para utilizar el Servicio, los Usuarios deben registrarse o crear una cuenta de Usuario, proporcionando todos los datos o información requerida de manera completa y veraz.</p>
<p>La falta de cumplimiento de esta obligación causará la indisponibilidad del Servicio.</p>
<p>Los Usuarios son responsables de mantener sus credenciales de inicio de sesión confidenciales y seguras. Por esta razón, se requiere que los Usuarios elijan contraseñas que cumplan con los estándares más altos de seguridad permitidos por betsmarter.app.</p>
<p>Al registrarse, los Usuarios aceptan ser plenamente responsables de todas las actividades que ocurran bajo su nombre de usuario y contraseña.</p>
<p>Los Usuarios deben informar al Propietario de manera inmediata e inequívoca, mediante los datos de contacto indicados en este documento, si creen que su información personal, incluidas las cuentas de Usuario, credenciales de acceso o datos personales, han sido violados, divulgados indebidamente o robados.</p>
<h3 id="condiciones-para-el-registro-de-cuenta"><strong>Condiciones para el registro de cuenta</strong></h3>
<p>El registro de cuentas de Usuario en betsmarter.app está sujeto a las condiciones descritas a continuación. Al registrarse, los Usuarios aceptan cumplir con tales condiciones.</p>
<ul>
<li>No se permiten cuentas registradas por bots u otros métodos automatizados.</li>
<li>Salvo que se especifique lo contrario, cada Usuario debe registrar solo una cuenta.</li>
<li>Salvo que se permita explícitamente, una cuenta de Usuario no puede compartirse con otras personas.</li>
</ul>
<h3 id="terminacion-de-cuenta"><strong>Terminación de cuenta</strong></h3>
<p>Los Usuarios pueden terminar su cuenta y dejar de usar el Servicio en cualquier momento mediante:</p>
<ul>
<li>Contactando directamente con el Propietario a través de los detalles de contacto proporcionados en este documento.</li>
</ul>
<h3 id="suspension-y-eliminacion-de-cuenta"><strong>Suspensión y eliminación de cuenta</strong></h3>
<p>El Propietario se reserva el derecho de suspender o terminar la cuenta del Usuario en cualquier momento y sin previo aviso, a discreción exclusiva del Propietario, en los siguientes casos:</p>
<ul>
<li>El Usuario ha violado estos Términos; y/o</li>
<li>El acceso o uso de betsmarter.app por parte del Usuario puede causar daño al Propietario, otros Usuarios o terceros; y/o</li>
<li>El uso de betsmarter.app por parte del Usuario puede causar violación de leyes o regulaciones; y/o</li>
<li>En caso de una investigación por acción legal o intervención gubernamental; y/o</li>
<li>La cuenta o su uso se considera, a discreción exclusiva del Propietario, inapropiado, ofensivo o en violación de estos Términos.</li>
</ul>
<p>La suspensión o eliminación de cuentas de Usuario no otorga a los Usuarios derecho alguno a reclamar compensación, daños o reembolso.</p>
<p>La suspensión o eliminación de cuentas debido a causas atribuibles al Usuario no exime al Usuario de pagar cualquier tarifa o precio aplicable.</p>
<h3 id="contenido-en-betsmarterapp"><strong>Contenido en betsmarter.app</strong></h3>
<p>Salvo que se especifique lo contrario o sea claramente reconocible, todo el contenido disponible en betsmarter.app es propiedad del Propietario o es proporcionado por sus licenciantes.</p>
<p>El Propietario realiza su mayor esfuerzo para garantizar que el contenido disponible en betsmarter.app no infringe disposiciones legales aplicables ni derechos de terceros. Sin embargo, no siempre es posible lograr tal resultado.</p>
<p>En tales casos, sin perjuicio de cualquier prerrogativa legal de los Usuarios para hacer valer sus derechos, se invita a los Usuarios a informar preferentemente sobre cualquier queja relacionada utilizando los datos de contacto proporcionados en este documento.</p>
<h3 id="derechos-sobre-el-contenido-en-betsmarterapp---todos-los-derechos-reservados"><strong>Derechos sobre el contenido en betsmarter.app - Todos los derechos reservados</strong></h3>
<p>El Propietario posee y reserva todos los derechos de propiedad intelectual sobre dicho contenido.</p>
<p>Por lo tanto, los Usuarios no pueden usar dicho contenido de ninguna manera que no sea necesaria o implícita para el uso adecuado del Servicio.</p>
<p>En particular, y sin limitación, los Usuarios no pueden copiar, descargar, compartir (más allá de los límites establecidos a continuación), modificar, traducir, transformar, publicar, transmitir, vender, sublicenciar, editar, transferir/asignar a terceros o crear trabajos derivados del contenido disponible en betsmarter.app, ni permitir que terceros lo hagan a través del Usuario o su dispositivo, incluso sin el conocimiento del Usuario.</p>
<p>Cuando se indique explícitamente en betsmarter.app, el Usuario podrá descargar, copiar y/o compartir algunos contenidos disponibles a través de betsmarter.app para su uso personal y no comercial, siempre que se implementen correctamente las atribuciones de derechos de autor y todas las demás atribuciones solicitadas por el Propietario.</p>
<p>Cualquier limitación o excepción estatutaria aplicable a los derechos de autor permanecerá sin cambios.</p>
<h3 id="acceso-a-recursos-externos"><strong>Acceso a recursos externos</strong></h3>
<p>A través de betsmarter.app, los Usuarios pueden tener acceso a recursos externos proporcionados por terceros. Los Usuarios reconocen y aceptan que el Propietario no tiene control sobre dichos recursos y, por lo tanto, no es responsable de su contenido y disponibilidad.</p>
<p>Las condiciones aplicables a cualquier recurso proporcionado por terceros, incluidas aquellas aplicables a cualquier posible concesión de derechos sobre contenido, derivan de los términos y condiciones de dichos terceros o, en ausencia de estos, de la legislación aplicable.</p>
<h3 id="uso-aceptable"><strong>Uso aceptable</strong></h3>
<p>Betsmarter.app y el Servicio solo pueden usarse dentro del alcance para el que se proporcionan, bajo estos Términos y la ley aplicable.</p>
<p>Los Usuarios son los únicos responsables de asegurarse de que su uso de betsmarter.app y/o el Servicio no viole ninguna ley aplicable, regulaciones o derechos de terceros.</p>
<p>Por lo tanto, <strong>el Propietario se reserva el derecho de tomar cualquier medida adecuada para proteger sus intereses legítimos, incluida la negación de acceso a betsmarter.app o al Servicio, la terminación de contratos, el reporte de cualquier mala conducta realizada a través de betsmarter.app o el Servicio a las autoridades competentes, como autoridades judiciales o administrativas, siempre que se sospeche que los Usuarios están en violación de cualquier ley, regulaciones, derechos de terceros y/o estos Términos, incluidos, entre otros, los siguientes:</strong></p>
<h3 id="restricciones-de-conducta"><strong>Restricciones de conducta</strong></h3>
<ul>
<li>Fingir cumplir con cualquier condición o requisito posible para acceder a betsmarter.app y/o usar los Servicios, como, por ejemplo, ser mayor de edad según la ley o calificar como Consumidor;</li>
<li>Ocultar su identidad o robar la identidad de otra persona, o pretender ser o representar a un tercero, si no tiene permiso para hacerlo de dicho tercero;</li>
<li>Manipular identificadores para disfrazar o de otro modo ocultar el origen de sus mensajes o del contenido publicado;</li>
<li>Difamar, abusar, acosar, usar prácticas amenazantes, amenazar o violar los derechos legales de otros de cualquier otra manera;</li>
<li>Promover actividades que puedan poner en peligro la vida del Usuario o de cualquier otro Usuario, o llevar a daños físicos. Esto incluye, pero no se limita a, amenazas de suicidio o incitaciones, traumatismo físico intencional, el uso de drogas ilegales o consumo excesivo de alcohol. Bajo ninguna circunstancia se permite a ningún Usuario publicar ningún contenido que promueva y/o fomente y/o muestre conductas autodestructivas o violentas en betsmarter.app;</li>
<li>Probar, escanear o probar la vulnerabilidad de betsmarter.app, incluidos los servicios o cualquier red conectada al sitio web, ni violar las medidas de seguridad o autenticación en betsmarter.app, incluidos los servicios o cualquier red conectada a betsmarter.app;</li>
<li>Instalar, incrustar, cargar o de otra manera incorporar cualquier malware en o a través de betsmarter.app;</li>
<li>Usar betsmarter.app o la infraestructura técnica de manera abusiva, excesiva o inapropiada (por ejemplo: con fines de spam);</li>
<li>Intentar interrumpir o manipular la infraestructura técnica de una manera que dañe o imponga una carga indebida en betsmarter.app o el Servicio;</li>
</ul>
<h3 id="scrapin"><strong>Scraping</strong></h3>
<ul>
<li>Adoptar cualquier proceso automatizado para extraer, recolectar o raspar información, datos y/o contenido de betsmarter.app y todas las propiedades digitales relacionadas a menos que el Propietario lo permita explícitamente.</li>
</ul>
<h3 id="restricciones-de-contenido"><strong>Restricciones de contenido</strong></h3>
<ul>
<li>Difundir o publicar contenido que sea ilegal, obsceno, ilegítimo, difamatorio o inapropiado;</li>
<li>Publicar cualquier contenido que promueva, directa o indirectamente, odio, racismo, discriminación, pornografía, violencia;</li>
<li>Difundir o publicar cualquier contenido que sea falso o pueda crear alarma injustificada;</li>
<li>Usar betsmarter.app para publicar, difundir o de otro modo proporcionar contenido protegido por leyes de propiedad intelectual, incluyendo pero no limitado a derechos de patente, marca comercial o derechos de autor, de manera ilegal y sin el consentimiento del titular legítimo;</li>
<li>Usar betsmarter.app para publicar, difundir o de otro modo poner a disposición cualquier otro contenido que infrinja los derechos de terceros, incluyendo pero no limitado a secretos de estado, militares, comerciales o profesionales y datos personales;</li>
<li>Publicar cualquier contenido o realizar cualquier actividad que interrumpa, interfiera, dañe o de otra manera viole la integridad de betsmarter.app o la experiencia o dispositivos de otro Usuario. Tales actividades incluyen: spam, distribución de anuncios no autorizados, phishing, fraude, difusión de malware o virus, etc.;</li>
</ul>
<h3 id="recomienda-a-un-amigo"><strong>“Recomienda a un amigo”</strong></h3>
<p>Betsmarter.app ofrece a los Usuarios la oportunidad de recibir ventajas si, como resultado de su recomendación, un nuevo Usuario adquiere un Producto ofrecido en betsmarter.app.</p>
<p>Para aprovechar esta oferta, los Usuarios pueden invitar a otros a adquirir los Productos en betsmarter.app enviándoles un código de recomendación proporcionado por el Propietario. Dichos códigos solo pueden canjearse una vez.</p>
<p>Si al adquirir los Productos en betsmarter.app alguna de las personas invitadas canjea un código de recomendación, el Usuario que hizo la invitación recibirá la ventaja o beneficio (como: una reducción de precio, una característica adicional del servicio, una mejora, etc.) especificado en betsmarter.app.</p>
<p>Los códigos de recomendación pueden estar limitados a productos específicos entre los ofrecidos en betsmarter.app.</p>
<p>El Propietario se reserva el derecho de finalizar la oferta en cualquier momento a su propia discreción.</p>
<p>Si bien no existe una limitación general en cuanto a la cantidad de personas que se pueden invitar, la cantidad de ventajas o beneficios que cada Usuario que invita puede recibir puede estar limitada.</p>
<h3 id="terminos-de-uso-de-la-api"><strong>Términos de uso de la API</strong></h3>
<p>Los Usuarios pueden acceder a sus datos relacionados con betsmarter.app a través de la Interfaz de Programación de Aplicaciones (API). Cualquier uso de la API, incluido el uso de la API a través de un producto/servicio de terceros que acceda a betsmarter.app, está sujeto a estos Términos y, además, a los siguientes términos específicos:</p>
<ul>
<li>el Usuario entiende y acepta expresamente que el Propietario no asume ninguna responsabilidad y no será responsable de ningún daño o pérdida resultante del uso de la API o del uso de cualquier producto/servicio de terceros que acceda a los datos a través de la API.</li>
</ul>
<h1 id="terminos-y-condiciones-de-venta"><strong>TÉRMINOS Y CONDICIONES DE VENTA</strong></h1>
<h3 id="productos-pagados"><strong>Productos pagados</strong></h3>
<p>Algunos de los Productos proporcionados en betsmarter.app, como parte del Servicio, se ofrecen a cambio de pago.</p>
<p>Las tarifas, duración y condiciones aplicables a la compra de dichos Productos se describen a continuación y en las secciones dedicadas de betsmarter.app.</p>
<p>Para adquirir Productos, el Usuario debe registrarse o iniciar sesión en betsmarter.app.</p>
<h3 id="descripcion-del-producto"><strong>Descripción del producto</strong></h3>
<p>Los precios, descripciones o disponibilidad de los Productos se detallan en las respectivas secciones de betsmarter.app y están sujetos a cambios sin previo aviso.</p>
<p>Si bien los Productos en betsmarter.app se presentan con la mayor precisión técnica posible, la representación en betsmarter.app a través de cualquier medio (incluyendo, en su caso, material gráfico, imágenes, colores, sonidos) es solo de referencia y no implica garantía alguna en cuanto a las características del Producto adquirido.</p>
<p>Las características del Producto elegido se detallarán durante el proceso de compra.</p>
<h3 id="proceso-de-compra"><strong>Proceso de compra</strong></h3>
<p>Cualquier paso realizado desde la elección de un Producto hasta la presentación del pedido forma parte del proceso de compra.</p>
<p>El proceso de compra incluye los siguientes pasos:</p>
<ul>
<li>Los Usuarios deben elegir el Producto deseado y verificar su selección de compra.</li>
<li>Tras revisar la información mostrada en la selección de compra, los Usuarios pueden realizar el pedido enviándolo.</li>
</ul>
<h3 id="envio-del-pedido"><strong>Envío del pedido</strong></h3>
<p>Cuando el Usuario envía un pedido, se aplicará lo siguiente:</p>
<ul>
<li>El envío de un pedido determina la celebración del contrato y, por lo tanto, crea para el Usuario la obligación de pagar el precio, los impuestos y posibles tarifas y gastos adicionales, según se especifique en la página del pedido.</li>
<li>En caso de que el Producto adquirido requiera una acción del Usuario, como la provisión de información personal o datos, especificaciones o deseos especiales, el envío del pedido crea una obligación para el Usuario de cooperar en consecuencia.</li>
<li>Al enviar el pedido, los Usuarios recibirán un recibo que confirma que el pedido ha sido recibido.</li>
</ul>
<p>Todas las notificaciones relacionadas con el proceso de compra descrito se enviarán a la dirección de correo electrónico proporcionada por el Usuario para dichos fines.</p>
<h3 id="precios"><strong>Precios</strong></h3>
<p>Durante el proceso de compra y antes del envío del pedido, se informará a los Usuarios sobre cualquier tarifa, impuesto y costo (incluyendo, en su caso, los costos de entrega) que se les cobrará.</p>
<p>Los precios en betsmarter.app se muestran:</p>
<ul>
<li>incluyendo todas las tarifas, impuestos y costos aplicables.</li>
</ul>
<h3 id="metodos-de-pago"><strong>Métodos de pago</strong></h3>
<p>La información relacionada con los métodos de pago aceptados se pone a disposición durante el proceso de compra.</p>
<p>Algunos métodos de pago pueden estar disponibles solo bajo condiciones o tarifas adicionales. En tales casos, la información relacionada se puede encontrar en la sección dedicada de betsmarter.app.</p>
<p>Todos los pagos se procesan de manera independiente a través de servicios de terceros. Por lo tanto, betsmarter.app no recopila ninguna información de pago, como detalles de tarjetas de crédito; solo recibe una notificación una vez que el pago ha sido completado con éxito.</p>
<p>Si un pago a través de los métodos disponibles falla o es rechazado por el proveedor de servicios de pago, el Propietario no estará obligado a cumplir con el pedido de compra. En caso de que un pago falle o sea rechazado, el Propietario se reserva el derecho de reclamar al Usuario cualquier gasto o daño relacionado.</p>
<h3 id="autorizacion-para-pago-futuro-con-paypal"><strong>Autorización para pago futuro con PayPal</strong></h3>
<p>Si los Usuarios autorizan la función de PayPal que permite compras futuras, betsmarter.app almacenará un código de identificación vinculado a la cuenta de PayPal de los Usuarios. Esto autorizará a betsmarter.app a procesar automáticamente pagos para futuras compras o cuotas recurrentes de compras anteriores.</p>
<p>Esta autorización puede ser revocada en cualquier momento, ya sea contactando al Propietario o cambiando la configuración de usuario que ofrece PayPal.</p>
<h3 id="retencion-de-los-derechos-de-uso"><strong>Retención de los derechos de uso</strong></h3>
<p>Los Usuarios no adquieren ningún derecho para usar el Producto adquirido hasta que el Propietario reciba el precio total de compra.</p>
<h3 id="entrega"><strong>Entrega</strong></h3>
<h3 id="realizacion-de-servicios"><strong>Realización de servicios</strong></h3>
<p>El servicio adquirido se realizará o estará disponible dentro del plazo especificado en betsmarter.app o según se comunique antes del envío del pedido.</p>
<h3 id="duracion-del-contrato"><strong>Duración del contrato</strong></h3>
<h3 id="suscripciones"><strong>Suscripciones</strong></h3>
<p>Las suscripciones permiten a los Usuarios recibir un Producto de forma continua o regular en el tiempo. A continuación se detallan los tipos de suscripción y terminación.</p>
<h3 id="suscripciones-de-duracion-fija"><strong>Suscripciones de duración fija</strong></h3>
<p>Las suscripciones pagadas de duración fija comienzan el día en que el Propietario recibe el pago y duran el período de suscripción elegido por el Usuario o el que se especifique durante el proceso de compra.</p>
<p>Una vez que expire el período de suscripción, el Producto ya no estará disponible.</p>
<h1 id="derechos-del-usuario"><strong>Derechos del Usuario</strong></h1>
<h3 id="derecho-de-desistimiento"><strong>Derecho de desistimiento</strong></h3>
<p>Salvo que se apliquen excepciones, el Usuario puede tener derecho a desistir del contrato dentro del período especificado a continuación (generalmente 14 días), por cualquier motivo y sin justificación. Los Usuarios pueden obtener más información sobre las condiciones de desistimiento en esta sección.</p>
<h3 id="a-quien-se-aplica-el-derecho-de-desistimiento"><strong>A quién se aplica el derecho de desistimiento</strong></h3>
<p>Salvo que se indique alguna excepción aplicable a continuación, los Usuarios que sean Consumidores europeos tienen un derecho legal de cancelación bajo las normas de la UE para desistir de los contratos celebrados en línea (contratos a distancia) dentro del período especificado aplicable a su caso, por cualquier motivo y sin justificación.</p>
<p>Los Usuarios que no se ajusten a esta cal
<h3 id="ejercicio-del-derecho-de-desistimiento"><strong>Ejercicio del derecho de desistimiento</strong></h3>
<p>Para ejercer su derecho de desistimiento, los Usuarios deben enviar al Propietario una declaración inequívoca de su intención de desistir del contrato.</p>
<p>Para ello, los Usuarios pueden utilizar el formulario de desistimiento modelo disponible en la sección “definiciones” de este documento. Sin embargo, los Usuarios son libres de expresar su intención de desistir del contrato haciendo una declaración inequívoca de cualquier otra manera adecuada. Para cumplir con el plazo en el que pueden ejercer dicho derecho, los Usuarios deben enviar el aviso de desistimiento antes de que expire el período de desistimiento.</p>
<p>¿Cuándo expira el período de desistimiento?</p>
<ul>
<li><strong>Con respecto a la compra de un servicio</strong>, el período de desistimiento expira 14 días después del día en que se celebre el contrato, a menos que el Usuario haya renunciado al derecho de desistimiento.</li>
</ul>
<h3 id="efectos-del-desistimiento"><strong>Efectos del desistimiento</strong></h3>
<p>Los Usuarios que desistan correctamente de un contrato serán reembolsados por el Propietario por todos los pagos realizados al Propietario, incluidos, en su caso, los costos de entrega.</p>
<p>Sin embargo, no se reembolsarán los costos adicionales resultantes de la elección de un método de entrega particular distinto del tipo menos costoso de entrega estándar ofrecido por el Propietario.</p>
<p>Dicho reembolso se efectuará sin demora indebida y, en cualquier caso, a más tardar 14 días a partir del día en que el Propietario sea informado de la decisión del Usuario de desistir del contrato. Salvo que se acuerde lo contrario con el Usuario, los reembolsos se realizarán utilizando el mismo medio de pago que se utilizó para procesar la transacción inicial. En cualquier caso, el Usuario no incurrirá en ningún costo ni tarifa como resultado de dicho reembolso.</p>
<h3 id="sobre-la-compra-de-servicios"><strong>…sobre la compra de servicios</strong></h3>
<p>Cuando un Usuario ejerce el derecho de desistimiento después de haber solicitado que el servicio se realice antes de que expire el período de desistimiento, el Usuario deberá pagar al Propietario un monto que sea proporcional a la parte del servicio proporcionado.</p>
<p>Dicho pago se calculará en función de la tarifa acordada contractualmente y será proporcional a la parte del servicio proporcionado hasta el momento en que el Usuario desista, en comparación con la cobertura total del contrato.</p>
<h1 id="responsabilidad-e-indemnizacion"><strong>Responsabilidad e indemnización</strong></h1>
<h3 id="indemnizacion"><strong>Indemnización</strong></h3>
<p>El Usuario se compromete a indemnizar y mantener indemne al Propietario y sus subsidiarias, afiliados, funcionarios, directores, agentes, socios y empleados de cualquier reclamo o demanda — incluyendo, pero no limitado a, honorarios de abogados y costos — realizada por terceros debido a o en relación con cualquier uso culpable o conexión con el Servicio, violación de estos Términos, infracción de cualquier derecho de terceros o disposición legal por parte del Usuario o sus afiliados, funcionarios, directores, agentes, co-marcas, socios y empleados en la medida permitida por la ley aplicable.</p>
<h3 id="limitacion-de-responsabilidad"><strong>Limitación de responsabilidad</strong></h3>
<p>Salvo que se indique expresamente lo contrario y sin perjuicio de las disposiciones legales aplicables de responsabilidad por productos, los Usuarios no tendrán derecho a reclamar daños y perjuicios contra el Propietario (o cualquier persona natural o jurídica que actúe en su nombre).</p>
<p>Esto no se aplica a daños a la vida, la salud o la integridad física, daños resultantes de la violación de una obligación contractual esencial, como cualquier obligación estrictamente necesaria para lograr el propósito del contrato, y/o daños resultantes de dolo o negligencia grave, siempre que betsmarter.app haya sido utilizado apropiadamente y de manera correcta por el Usuario.</p>
<p>A menos que los daños hayan sido causados intencionalmente o por negligencia grave, o afecten la vida, la salud o la integridad física, el Propietario solo será responsable en la medida de los daños típicos y previsibles en el momento en que se celebró el contrato.</p>
<h1 id="disposiciones-comunes"><strong>Disposiciones comunes</strong></h1>
<h3 id="no-renuncia"><strong>No renuncia</strong></h3>
<p>El hecho de que el Propietario no haga valer cualquier derecho o disposición bajo estos Términos no constituirá una renuncia a dicho derecho o disposición. Ninguna renuncia se considerará como una renuncia continua o posterior de dicho término o cualquier otro término.</p>
<h3 id="interrupcion-del-servicio"><strong>Interrupción del servicio</strong></h3>
<p>Para garantizar el mejor nivel de servicio posible, el Propietario se reserva el derecho de interrumpir el Servicio para mantenimiento, actualizaciones del sistema o cualquier otro cambio, informando a los Usuarios adecuadamente.</p>
<p>Dentro de los límites de la ley, el Propietario también puede decidir suspender o interrumpir el Servicio por completo. Si el Servicio se interrumpe, el Propietario cooperará con los Usuarios para permitirles retirar sus Datos Personales o información y respetará los derechos de los Usuarios relacionados con el uso continuado del producto y/o la compensación, según lo dispuesto por la ley aplicable.</p>
<p>Además, el Servicio podría no estar disponible debido a razones fuera del control razonable del Propietario, como eventos de “fuerza mayor” (fallos de infraestructura o apagones, etc.).</p>
<h3 id="reventa-del-servicio"><strong>Reventa del servicio</strong></h3>
<p>Los Usuarios no pueden reproducir, duplicar, copiar, vender, revender ni explotar ninguna parte de betsmarter.app y su Servicio sin el permiso previo por escrito del Propietario, otorgado ya sea directamente o a través de un programa de reventa legítimo.</p>
<h3 id="politica-de-privacidad"><strong>Política de privacidad</strong></h3>
<p>Para obtener más información sobre el uso de sus Datos Personales, los Usuarios pueden consultar la política de privacidad de betsmarter.app.</p>
<h3 id="derechos-de-propiedad-intelectual"><strong>Derechos de propiedad intelectual</strong></h3>
<p>Sin perjuicio de cualquier disposición más específica de estos Términos, cualquier derecho de propiedad intelectual, como derechos de autor, derechos de marcas, derechos de patentes y derechos de diseño relacionados con betsmarter.app son propiedad exclusiva del Propietario o de sus licenciantes y están sujetos a la protección otorgada por las leyes aplicables o tratados internacionales relacionados con la propiedad intelectual.</p>
<p>Todas las marcas comerciales — nominales o figurativas — y todas las demás marcas, nombres comerciales, marcas de servicio, marcas de palabras, ilustraciones, imágenes o logotipos que aparezcan en relación con betsmarter.app son y siguen siendo propiedad exclusiva del Propietario o sus licenciantes y están sujetas a la protección otorgada por las leyes aplicables o tratados internacionales relacionados con la propiedad intelectual.</p>
<h3 id="cambios-en-estos-terminos"><strong>Cambios en estos Términos</strong></h3>
<p>El Propietario se reserva el derecho de enmendar o modificar estos Términos en cualquier momento. En tales casos, el Propietario informará adecuadamente al Usuario de dichos cambios.</p>
<p>Estos cambios solo afectarán la relación con el Usuario desde la fecha comunicada a los Usuarios en adelante.</p>
<p>El uso continuado del Servicio significará la aceptación de los Términos revisados por parte del Usuario. Si los Usuarios no desean estar sujetos a los cambios, deben dejar de usar el Servicio y pueden terminar el Acuerdo.</p>
<p>La versión aplicable anterior regirá la relación antes de la aceptación del Usuario. El Usuario puede obtener cualquier versión anterior del Propietario.</p>
<p>Si la ley lo requiere, el Propietario notificará a los Usuarios con antelación sobre cuándo los Términos modificados entrarán en vigor.</p>
<h3 id="cesion-de-contrato"><strong>Cesión de contrato</strong></h3>
<p>El Propietario se reserva el derecho de transferir, ceder, disponer por novación o subcontratar cualquiera o todos los derechos u obligaciones bajo estos Términos, teniendo en cuenta los intereses legítimos del Usuario. Las disposiciones relativas a los cambios de estos Términos se aplicarán en consecuencia.</p>
<p>Los Usuarios no pueden ceder ni transferir sus derechos u obligaciones bajo estos Términos de ninguna manera, sin el permiso por escrito del Propietario.</p>
<h3 id="contactos"><strong>Contactos</strong></h3>
<p>Todas las comunicaciones relacionadas con el uso de betsmarter.app deben enviarse utilizando la información de contacto indicada en este documento.</p>
<h3 id="divisibilidad"><strong>Divisibilidad</strong></h3>
<p>Si alguna disposición de estos Términos se considera o se convierte en inválida o inaplicable según la ley aplicable, la invalidez o inaplicabilidad de dicha disposición no afectará la validez de las disposiciones restantes, que permanecerán en pleno vigor y efecto.</p>
<h3 id="usuarios-de-eeuu"><strong>Usuarios de EE.UU.</strong></h3>
<p>Cualquier disposición inválida o inaplicable será interpretada, construida y reformada en la medida razonablemente necesaria para que sea válida, ejecutable y consistente con su intención original. Estos Términos constituyen el Acuerdo completo entre los Usuarios y el Propietario con respecto al objeto del presente, y reemplazan todas las demás comunicaciones, incluidos pero no limitados a todos los acuerdos previos, entre las partes con respecto a dicho objeto. Estos Términos se aplicarán en la máxima medida permitida por la ley.</p>
<h3 id="usuarios-de-la-ue"><strong>Usuarios de la UE</strong></h3>
<p>Si alguna disposición de estos Términos se considera nula, inválida o inaplicable, las partes harán lo posible por encontrar, de manera amistosa, un acuerdo sobre disposiciones válidas y aplicables que sustituyan a las partes nulas, inválidas o inaplicables.</p>
<p>En caso de no hacerlo, las disposiciones nulas, inválidas o inaplicables serán reemplazadas por las disposiciones legales aplicables, si así lo permite o establece la ley aplicable.</p>
<p>Sin perjuicio de lo anterior, la nulidad, invalidez o imposibilidad de hacer cumplir una disposición particular de estos Términos no anulará el Acuerdo completo, a menos que las disposiciones anuladas sean esenciales para el Acuerdo, o de tal importancia que las partes no habrían celebrado el contrato si hubieran sabido que la disposición no sería válida, o en los casos en que las disposiciones restantes traducirían una dificultad inaceptable para cualquiera de las partes.</p>
<h3 id="ley-aplicable"><strong>Ley aplicable</strong></h3>
<p>Estos Términos se rigen por la ley del lugar donde se encuentra el Propietario, según lo indicado en la sección correspondiente de este documento, sin tener en cuenta los principios de conflicto de leyes.</p>
<h3 id="prevalencia-de-la-ley-nacional"><strong>Prevalencia de la ley nacional</strong></h3>
<p>Sin embargo, independientemente de lo anterior, si la ley del país en el que se encuentra el Usuario proporciona un estándar de protección al consumidor más alto, dichos estándares más altos prevalecerán.</p>
<h3 id="jurisdiccion"><strong>Jurisdicción</strong></h3>
<p>La competencia exclusiva para decidir sobre cualquier controversia derivada de o relacionada con estos Términos corresponde a los tribunales del lugar donde se encuentra el Propietario, tal como se muestra en la sección correspondiente de este documento.</p>
<h3 id="excepcion-para-consumidores-en-europa"><strong>Excepción para consumidores en Europa</strong></h3>
<p>Lo anterior no se aplica a ningún Usuario que califique como Consumidor europeo, ni a Consumidores ubicados en el Reino Unido, Suiza, Noruega o Islandia.</p>
<h1 id="resolucion-de-disputas"><strong>Resolución de disputas</strong></h1>
<h3 id="resolucion-amistosa-de-disputas"><strong>Resolución amistosa de disputas</strong></h3>
<p>Los Usuarios pueden presentar cualquier disputa al Propietario, quien intentará resolverlas de manera amistosa.</p>
<p>Si bien el derecho de los Usuarios a emprender acciones legales permanecerá sin cambios, en caso de cualquier controversia con respecto al uso de betsmarter.app o del Servicio, se invita a los Usuarios a contactar con el Propietario mediante los detalles de contacto proporcionados en este documento.</p>
<p>El Usuario puede presentar la queja, incluyendo una breve descripción y, si corresponde, los detalles del pedido, compra o cuenta relacionada, a la dirección de correo electrónico del Propietario especificada en este documento.</p>
<p>El Propietario procesará la queja sin demora indebida y dentro de los 21 días posteriores a su recepción.</p>
<h3 id="resolucion-de-disputas-en-linea-para-consumidores"><strong>Resolución de disputas en línea para consumidores</strong></h3>
<p>La Comisión Europea ha establecido una plataforma en línea para la resolución alternativa de disputas que facilita un método extrajudicial para resolver disputas relacionadas y derivadas de contratos de venta y servicio en línea.</p>
<p>Como resultado, cualquier Consumidor europeo o Consumidor ubicado en Noruega, Islandia o Liechtenstein puede utilizar dicha plataforma para resolver disputas derivadas de contratos celebrados en línea. La plataforma está <a href="http://ec.europa.eu/consumers/odr/">disponible en el siguiente enlace</a>.</p>
<h2 id="definiciones-y-referencias-legales"><strong>Definiciones y referencias legales</strong></h2>
<p>Última actualización: 19 de junio de 2023</p>
`,
};

export function Terms() {
  const { i18n } = useTranslation();

  // Get the current language
  const currentLanguage = i18n.language;

  return (
    <div className="min-h-screen bg-[#111E12]">
      <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="bg-white shadow-sm rounded-xl p-6 sm:p-8">
          <div className="prose prose-sm sm:prose lg:prose-lg mx-auto">
            {ReactHtmlParser(termsByLanguage[currentLanguage], {
              replace: (node) => {
                if (node.type === 'tag') {
                  // Style headings
                  if (node.name === 'h1') {
                    node.attribs.class = 'text-2xl sm:text-3xl font-bold text-gray-900 mb-4 mt-8';
                  }
                  // Style subheadings
                  if (node.name === 'h2' || node.name === 'h3') {
                    node.attribs.class = 'text-xl sm:text-2xl font-semibold text-gray-800 mb-3 mt-6';
                  }
                  // Style paragraphs
                  if (node.name === 'p') {
                    node.attribs.class = 'text-gray-600 mb-4 leading-relaxed';
                  }
                  // Style lists
                  if (node.name === 'ul' || node.name === 'ol') {
                    node.attribs.class = 'list-disc ml-6 mb-4 text-gray-600 space-y-2';
                  }
                  // Style list items
                  if (node.name === 'li') {
                    node.attribs.class = 'text-gray-600';
                  }
                  // Style links
                  if (node.name === 'a') {
                    node.attribs.class = 'text-blue-600 hover:text-blue-800 underline';
                  }
                  // Style horizontal rules
                  if (node.name === 'hr') {
                    node.attribs.class = 'my-8 border-t border-gray-200';
                  }
                  // Style strong tags
                  if (node.name === 'strong') {
                    node.attribs.class = 'font-semibold text-gray-900';
                  }
                }
                return undefined;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
