import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useTranslation } from "react-i18next"

const initialBookmakers = [
  { name: "Bet365", minAmount: 150, maxAmount: 150, nextDepositAmount: 300 },
  { name: "Casumo", clones: ["paf", "leovegas", "yosports", "enracha"], minAmount: 150, maxAmount: 150, nextDepositAmount: 300 },
  { name: "Jokerbet", minAmount: 100, maxAmount: 150, nextDepositAmount: 300 },
  { name: "Tonybet",  minAmount: 50, maxAmount: 150, nextDepositAmount: 300},
  { name: "Winamax", minAmount: 50, maxAmount: 150, nextDepositAmount: 300 },
  { name: "Retabet", minAmount: 100, maxAmount: 150, nextDepositAmount: 150 },
  { name: "Bet777", minAmount: 100, maxAmount: 150, nextDepositAmount: 100 },
  { name: "Pokerstars", minAmount: 100, maxAmount: 150, nextDepositAmount: 100 },
  { name: "Sportium", minAmount: 100, maxAmount: 150, nextDepositAmount: 100 },
  { name: "Betway", minAmount: 100, maxAmount: 150, nextDepositAmount: 100 },
]

const roundToTen = (num) => Math.round(num / 10) * 10

export default function SexyBookmakerAllocationTool() {
  const { t } = useTranslation();
  const [bankAmount, setBankAmount] = useState(2000)
  const [allocations, setAllocations] = useState({})
  const [selectedBookmakers, setSelectedBookmakers] = useState(
    initialBookmakers.map(b => b.name)
  )

  useEffect(() => {
    calculateAllocations()
  }, [bankAmount, selectedBookmakers])

  const calculateAllocations = () => {
    let remainingAmount = bankAmount
    const reserveAmount = Math.ceil(bankAmount * 0.25 / 10) * 10 // 25% of bank, rounded up to nearest 10
    const newAllocations = {}
    const selectedBookmakerData = initialBookmakers.filter(b => selectedBookmakers.includes(b.name))

    selectedBookmakerData.forEach((bookmaker) => {
      if (remainingAmount <= reserveAmount) {
        newAllocations[bookmaker.name] = 0
        return
      }

      let allocation = Math.min(bookmaker.maxAmount, remainingAmount - reserveAmount)
      allocation = Math.max(allocation, bookmaker.minAmount)
      allocation = roundToTen(allocation)

      if (allocation > remainingAmount - reserveAmount) {
        allocation = 0
      } else {
        remainingAmount -= allocation
      }

      newAllocations[bookmaker.name] = allocation
    })

    setAllocations(newAllocations)
  }

  const handleSliderChange = (e) => {
    setBankAmount(roundToTen(Number(e.target.value)))
  }

  const handleInputChange = (e) => {
    const value = roundToTen(Number(e.target.value))
    if (value >= 1000 && value <= 3500) {
      setBankAmount(value)
    }
  }

  const toggleBookmaker = (bookmakerName) => {
    setSelectedBookmakers(prev => 
      prev.includes(bookmakerName)
        ? prev.filter(name => name !== bookmakerName)
        : [...prev, bookmakerName]
    )
  }

  const totalAllocated = Object.values(allocations).reduce((sum, value) => sum + value, 0)
  const notAllocated = bankAmount - totalAllocated

  const BookmakerCard = ({ bookmaker }) => {
    const { t } = useTranslation();
    
    return (
      <motion.div
        key={bookmaker.name}
        className="rounded-xl p-4 shadow-md bg-[#111E12]"
      >
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center space-x-3">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={selectedBookmakers.includes(bookmaker.name)}
                onChange={() => toggleBookmaker(bookmaker.name)}
                className="form-checkbox h-5 w-5 text-white rounded border-gray-300 focus:ring-[#E8FC8B]"
              />
              <span className="ml-2 text-white font-medium text-lg">{bookmaker.name}</span>
            </label>
          </div>
          <span className={`font-bold text-lg ${allocations[bookmaker.name] === 0 ? 'text-white' : 'text-[#E8FC8B]'}`}>
            {allocations[bookmaker.name]?.toFixed(2) || "0.00"}€
          </span>
        </div>
        <div className="mt-2 text-sm text-gray-300">
          <p>{t('minAmount')}: {bookmaker.minAmount}€</p>
          <p>{t('nextDeposit')}: {bookmaker.nextDepositAmount}€</p>
        </div>
        {bookmaker.clones && (
          <div className="mt-2">
            <p className="text-xs text-[#E8FC8B] mb-1">Clones:</p>
            <div className="flex flex-wrap gap-2">
              {bookmaker.clones.map((clone) => (
                <span key={clone} className="px-2 py-1 bg-[#E9F8DC] bg-opacity-50 text-[#1B2D1A] rounded-full text-sm">
                  {clone}
                </span>
              ))}
            </div>
          </div>
        )}
      </motion.div>
    )
  }

  return (
    <div className="min-h-screen flex items-start justify-center w-full max-w-[1600px] mx-auto">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-[#1B2D1A] rounded-xl shadow-2xl w-full overflow-hidden"
      >
        <div className="bg-[#1B2D1A] text-white p-8 relative overflow-hidden">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 260, damping: 20 }}
            className="absolute -top-12 -right-12 w-40 h-40 bg-[#E8FC8B] rounded-full opacity-10"
          />
          <h1 className="text-3xl font-bold mb-2">{t("calculator")}</h1>
          <p className="text-gray-300 mt-2">{t("calculatorExplanation")}</p>
        </div>
        <div className="p-8 space-y-8">
          <div className="space-y-4">
            <label htmlFor="bankAmount" className="text-white font-medium text-lg block">
              {t("totalBankAmount")}
            </label>
            <div className="flex items-center space-x-4">
              <input
                type="range"
                id="bankAmountSlider"
                min="1000"
                max="3500"
                step="10"
                value={bankAmount}
                onChange={handleSliderChange}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              <div className="relative">
                <input
                  id="bankAmount"
                  type="number"
                  value={bankAmount}
                  onChange={handleInputChange}
                  min="1000"
                  max="3500"
                  className="pl-8 w-28 py-2 px-3 border border-gray-300 rounded-md text-[#1B2D1A] focus:outline-none focus:ring-2 focus:ring-[#E8FC8B] focus:border-[#E8FC8B]"
                />
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AnimatePresence>
                {initialBookmakers.map((bookmaker) => (
                  <BookmakerCard key={bookmaker.name} bookmaker={bookmaker} />
                ))}
              </AnimatePresence>
            </div>
          </div>
          <motion.div 
            className="pt-4 border-t border-[#ABCDDF] space-y-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <div className="flex justify-between items-center text-white font-semibold">
              <span className="text-lg">{t("totalAllocated")}</span>
              <span className="text-[#E8FC8B] text-xl">{totalAllocated.toFixed(2)}€</span>
            </div>
            <div className="flex justify-between items-center text-white font-semibold">
              <span className="text-lg">{t("remaining")}</span>
              <span className="text-[#B8DCEF] text-xl">{notAllocated.toFixed(2)}€</span>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  )
}
