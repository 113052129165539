import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";

import {
    SubscriptionCard,
    SubscriptionCardHeader,
    SubscriptionTitle,
    SubscriptionSubtitle,
    SubscriptionStatus,
    SubscriptionButtons,
    CancelButton,
    FreezeButton,
    ModalButton,
} from '../UserHome.styled';

const translateError = (error) => {
    const errors = {
      already_freezed: "Your subscription is already freezed",
      not_existing: "Your subscription does not exist",
      less_than_one_day:
        "Tienes que tener al menos un día restante de suscripción para poder cancelarla",
      already_cancelled: "Your subscription is already cancelled",
      already_unfreezed: "Your subscription is already unfreezed",
      not_freezed: "Your subscription is not freezed",
    };
    return errors[error] || error;
  };

export const SubscriptionSection = ({ subscriptionStatus, handleSubscriptionAction, t }) => {
    const [showFreezeSubscription, setShowFreezeSubscription] = useState(false);
    const [showUnfreezeSubscription, setShowUnfreezeSubscription] = useState(false);
    const [showCancelSubscription, setShowCancelSubscription] = useState(false);
    const [freezerError, setFreezerError] = useState(undefined);

    // get milliseconds formated in days, hours, minutes and seconds
    const getFormattedTime = (milliseconds) => {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        // return string with days, hours, minutes and seconds
        return `${days}d ${hours % 24}h ${minutes % 60}m ${seconds % 60}s`;
    };

    const isSubscriptionNonExisting =
        subscriptionStatus && subscriptionStatus.status === "NON_EXISTING";

    const getSubscriptionStatusText = () => {
        if (!isSubscriptionNonExisting &&
            subscriptionStatus && subscriptionStatus.expiresOn &&
            ["ACTIVE", "CANCELLED", "SUSPENDED"].includes(
                subscriptionStatus.status
            )) {
            return subscriptionStatus.status === "ACTIVE" ? `${t('activeUntil')} ` + new Date(subscriptionStatus?.expiresOn).toLocaleDateString() : `${t('expiresOn')} ` + new Date(subscriptionStatus?.expiresOn).toLocaleDateString();
        }
        else if (!isSubscriptionNonExisting &&
            subscriptionStatus && subscriptionStatus.expiresOn &&
            ["FREEZED"].includes(subscriptionStatus.status)) {
            return <>
                <p><b>{t('freezedOn')}: </b>{new Date(subscriptionStatus?.updatedOn).toLocaleDateString()}</p>
                <p><b>{t('frozenTime')}: </b>{getFormattedTime(
                    new Date() -
                    new Date(subscriptionStatus.updatedOn)
                )}</p>
            </>
        }
    }

    return (
        <SubscriptionCard>
            <SubscriptionCardHeader>
                <SubscriptionTitle>{t('currentSubscription')}</SubscriptionTitle>
                <SubscriptionSubtitle>Pro Subscription</SubscriptionSubtitle>
            </SubscriptionCardHeader>
            <SubscriptionStatus>{getSubscriptionStatusText()}</SubscriptionStatus>
            <SubscriptionButtons>
                {subscriptionStatus?.status === "ACTIVE" && (
                    <CancelButton onClick={() => setShowCancelSubscription(true)}>
                        {t('cancelSubscription')}
                    </CancelButton>
                )}
                {["CANCELLED", "ACTIVE", "SUSPENDED"].includes(subscriptionStatus?.status) && !(subscriptionStatus?.id?.includes("COUPON")) && (
                    <FreezeButton onClick={() => setShowFreezeSubscription(true)}>
                        {t('freezeSubscription')}
                    </FreezeButton>
                )}
                {subscriptionStatus?.status === "FREEZED" && (
                    <FreezeButton onClick={() => setShowUnfreezeSubscription(true)}>
                        {t('unfreezeSubscription')}
                    </FreezeButton>
                )}
            </SubscriptionButtons>
            <Modal
                show={showFreezeSubscription}
                onHide={() => {
                    setShowFreezeSubscription(false);
                    setFreezerError(undefined);
                }}
                centered
            >
                <Modal.Header
                    style={{
                        backgroundColor: "#111E12",

                    }}
                    closeButton>
                    <Modal.Title
                        style={{

                            fontFamily: "Montserrat",
                        }}
                    >{t('freezeSubscription')}</Modal.Title>
                </Modal.Header>
                {freezerError && (
                    <Alert variant="danger" className="customAlert">
                        ERROR: {translateError(freezerError)}
                    </Alert>
                )}
                <Modal.Body
                    style={{
                        backgroundColor: "#111E12",
                        fontFamily: "Montserrat",
                    }}
                >
                    {
                        t('freezeText').split('\\n').map((item, key) => {
                            return <p key={key}>{item}</p>
                        }
                        )
                    }
                    {subscriptionStatus?.status === "ACTIVE" && (
                        <Alert variant="info" className="customAlert">
                            {t('cancelationText3')}
                        </Alert>
                    )}
                    <ModalButton onClick={() => {
                        handleSubscriptionAction('freeze')
                        setShowFreezeSubscription(false)
                    }}>
                        {t('confirmFreeze')}
                    </ModalButton>
                </Modal.Body>
            </Modal>
            <Modal
                show={showUnfreezeSubscription}
                onHide={() => {
                    setShowUnfreezeSubscription(false);
                    setFreezerError(undefined);
                }}
                centered
            >
                <Modal.Header
                    style={{
                        backgroundColor: "#111E12",
                        fontFamily: "Montserrat",
                    }}
                    closeButton>
                    <Modal.Title>{t('unfreezeSubscription')}</Modal.Title>
                </Modal.Header>
                {freezerError && (
                    <Alert variant="danger" className="customAlert">
                        ERROR: {translateError(freezerError)}
                    </Alert>
                )}
                <Modal.Body
                    style={{
                        backgroundColor: "#111E12",
                        fontFamily: "Montserrat",
                    }}
                >
                    <p>
                        {t('unfreezeText')}
                    </p>
                    {new Date() - new Date(subscriptionStatus?.updatedOn) <
                        7 * 24 * 60 * 60 * 1000 && (
                            <Alert variant="danger" className="customAlert">
                                <b>{t('penaltyTime')}</b>
                                {" "}{t('frozenTime')}:{" "}
                                {getFormattedTime(
                                    new Date() - new Date(subscriptionStatus?.updatedOn)
                                )}
                            </Alert>
                        )}
                    <ModalButton
                        onClick={() => {
                            handleSubscriptionAction('unfreeze')
                            setShowUnfreezeSubscription(false)
                        }}>
                        {t('confirmUnfreeze')}
                    </ModalButton>
                </Modal.Body>
            </Modal>
            <Modal
                show={showCancelSubscription}
                onHide={() => setShowCancelSubscription(false)}
                centered
            >
                <Modal.Header
                    style={{
                        backgroundColor: "#111E12",
                        fontFamily: "Montserrat",
                    }}
                    closeButton>
                    <Modal.Title>{t('cancelSubscription')}</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        backgroundColor: "#111E12",
                        fontFamily: "Montserrat",
                    }}
                >
                    <p>
                        {t('cancelationText1')}{" "}
                        <b>
                            {new Date(subscriptionStatus?.expiresOn).toLocaleDateString()}
                        </b>
                        . {t('cancelationText2')}
                    </p>
                    <ModalButton onClick={() => {
                        handleSubscriptionAction('cancel')
                        setShowCancelSubscription(false)
                    }}>
                        {t('confirmCancelation')}
                    </ModalButton>
                </Modal.Body>
            </Modal>

        </SubscriptionCard>
    );
};