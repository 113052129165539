import React, { useState, useMemo, useEffect, useRef } from "react";
import { Card, AddAccountCard, AddCredit } from "..";
import "./UserBox.styled";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import { getCredit } from "../../utils/calculations";
import { useUser } from "../../hooks";
import {
  UserBoxContainer, BookiesContainer, BookiesHeader, EmailAndWatchBets, LeftSide, LeftSideInternal, PaymentHistory,
  RightSide, UserEmail, UserEmailText, UserProfit, WatchBets, Tags, UserTag, PopoverCustom
} from "./UserBox.styled";
import { withRouter } from 'react-router-dom'
import { TransferCredit } from "../TransferCredit/TransferCredit";
import { getUserName } from "../../utils/users";
import { useTranslation } from "react-i18next";

import { EyeIcon, PlusCircleIcon, AlertTriangleIcon, XCircleIcon, EyeOffIcon, SlidersHorizontal, ChevronDownIcon, Play, StopCircle, Bookmark, Info } from "lucide-react"


const UserBox = ({ history, user, enabled, isAdmin, modifyUser }) => {
  const { t } = useTranslation()
  const { disableEmail, enableEmail, setReadyStatus, addCredit, transferCredit } = useUser()
  const [showDisabledAccounts, setShowDisabledAccounts] = useState(false);
  const [show, setShow] = useState(false);
  const [showTransferCredit, setShowTransferCredit] = useState(false)
  const handleWatchBets = () => history.push(`/${user._id.email}/bets`);

  const handleAddBookie = () => history.push(`/bookies/${user._id.email}`);

  const isBookieEnabled = (bookie) => {
    const { enabled } = bookie;
    return enabled;
  };

  const changeAccountStatus = () => {
    enabled
      ? disableEmail(user._id.email).then(() => history.push('/home/bot'))
      : enableEmail(user._id.email).then(() => history.push('/home/bot'))
  }

  const changeAccountReadyStatus = () => setReadyStatus(user._id.email, !user?.isReady).then(() => history.push('/home/bot'))

  const toggleDisabledAccounts = () => setShowDisabledAccounts(!showDisabledAccounts);

  const handleAddCredit = async values => {
    await addCredit(user._id.email, { ...values, type: 'credit' });
    setShow(false);
  };

  const handleTransferCredit = async values => {
    await transferCredit(user._id.email, values.amount);
    setShowTransferCredit(false)
  }

  const getEnabledAccounts = () => user?.bookies.filter((bookie) => isBookieEnabled(bookie));

  const getDisabledAccounts = () => user?.bookies.filter((bookie) => !isBookieEnabled(bookie));

  const getCreditForUser = () => getCredit(user);

  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const totalAccounts = useMemo(() => user?.bookies, [user]);

  const visibleAccounts = useMemo(() => showDisabledAccounts ? totalAccounts : getEnabledAccounts(), [user, showDisabledAccounts]);

  return (
    <div className="bg-[#192619] shadow-lg rounded-xl">
      <div className="border-b border-[#ABCDDF]/20 p-4 sm:p-6">
      <div className="flex justify-start items-center gap-2 mb-2">
          {isAdmin && user?.isReady && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold text-green-100 bg-green-800">
              {t("ready")}
            </span>
          )}
          {user?.enabled && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold bg-green-100 text-green-800">
              {t("enabled")}
            </span>
          )}
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <div className="flex items-center space-x-2">
            <h2 className="text-white text-xl sm:text-2xl font-bold">{getUserName(user)}</h2>
          </div>
          <div className="flex flex-wrap gap-2 justify-start sm:justify-end">
            <button className="bg-[#E8FC8B] text-[#1B2D1A] hover:bg-[#E8FC8B]/90 px-3 py-1 sm:px-4 sm:py-2 rounded-md flex items-center space-x-1 text-sm sm:text-base" onClick={handleWatchBets}>
              <EyeIcon size={16} />
              <span>{t("watchBets")}</span>
            </button>
            <button className="bg-[#E8FC8B] text-[#1B2D1A] hover:bg-[#E8FC8B]/90 px-3 py-1 sm:px-4 sm:py-2 rounded-md flex items-center space-x-1 text-sm sm:text-base" onClick={handleAddBookie}>
              <PlusCircleIcon size={16} />
              <span>{t("addAccount")}</span>
            </button>
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setShowDropdown(showDropdown ? null : -1)}
                className="bg-[#3A4C39] text-[#ABCDDF] hover:bg-[#4A5C49] hover:text-[#E8FC8B] px-3 py-1 sm:px-4 sm:py-2 rounded-md flex items-center space-x-1 text-sm sm:text-base"
              >
                <SlidersHorizontal className="h-4 w-4 mr-1 sm:mr-2" />
                <span className="hidden sm:inline">{t("actions")}</span>
                <ChevronDownIcon className="h-4 w-4 ml-1 sm:ml-2" />
              </button>
              {showDropdown === -1 && (
                <div className="absolute max-sm:right-[-100px] sm:left-0 mt-2 w-48 sm:w-56 rounded-md shadow-lg bg-[#2A3C29] ring-1 ring-black ring-opacity-5 z-50">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <button
                      onClick={() => toggleDisabledAccounts()}
                      className="flex items-center w-full px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] transition-colors duration-200 hover:text-[#E8FC8B]"
                    >
                      {showDisabledAccounts ? (
                        <EyeIcon className="inline-block mr-2 h-4 w-4" />
                      ) : (
                        <EyeOffIcon className="inline-block mr-2 h-4 w-4" />
                      )}
                      {showDisabledAccounts ? <span className="flex-grow text-left">{t("showEnabledAccounts")}</span> : <span className="flex-grow text-left">{t("showDisabledAccounts")}</span>}
                    </button>
                    {isAdmin && (
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                        onClick={() => changeAccountStatus()}
                      >
                        {enabled ? <StopCircle className="inline-block mr-2 h-4 w-4" /> : <Play className="inline-block mr-2 h-4 w-4" />}
                        {enabled ? t("disableAccount") : t("enableAccount")}
                      </button>
                    )}
                    {isAdmin && (
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                        onClick={() => changeAccountReadyStatus()}
                      >
                        <Bookmark className="inline-block mr-2 h-4 w-4" />
                        {user?.isReady ? t("setNotReady") : t("setReady")}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-white/80 text-xs sm:text-sm mt-2 flex items-center gap-1">
          <span className="font-semibold">{t("profit").toUpperCase()}:</span> {Math.round(((user?.billing || {}).profit || 0) * 100) / 100}€
          <span>|</span>
          <span className="font-semibold">{getCreditForUser().amount < 0 ? t("credit").toUpperCase() : t("toPay").toUpperCase()}:</span> {Math.abs(getCreditForUser().amount)}€
          {getCreditForUser().payments.length > 0 && (
            <OverlayTrigger
              trigger="click"
              key="left"
              placement="bottom"
              overlay={
                <PopoverCustom id={`PopoverCustom-positioned-left`}>
                  <PopoverCustom.Title as="h3">
                    {t("paymentInfo")}
                  </PopoverCustom.Title>
                  <PopoverCustom.Content>
                    <p>
                      <strong>{t("rateOverProfit")}:</strong>{" "}
                      {getCreditForUser().rate}%
                    </p>
                    <a>
                      <strong>{t("paymentHistory")}</strong>
                    </a>
                    {getCreditForUser().payments.map((payment) => (
                      <PaymentHistory>
                        {t("paid")} <b>{payment.amount.toFixed(2)}€</b> el{" "}
                        <b>
                          {new Date(
                            payment.timestamp
                          ).toLocaleDateString()}
                        </b>
                      </PaymentHistory>
                    ))}
                  </PopoverCustom.Content>
                </PopoverCustom>
              }
            >
              <button className="ml-1 text-[#ABCDDF] bg-[#4A5C49] hover:text-[#E8FC8B] p-2 rounded-md">
                <Info className="h-4 w-4" />
              </button>
            </OverlayTrigger>
          )}
        </div>
      </div>
      {visibleAccounts?.length > 0 && <div className="grid gap-4 p-4 sm:grid-cols-2 lg:grid-cols-3">
        {visibleAccounts.map((account, index) => (
          <Card account={account}
            key={`${account.name}-${index}`}
            index={index}
            history={history}
            email={user._id.email}
            name={account.name}
            enabled={account.enabled}
            bookie={account}
            isAdmin={isAdmin}
            status={account.status}
            username={account.username}
            balance={account.balance}
          />
        ))}
      </div>
      }
      <div className="border-t border-[#ABCDDF]/20 p-4">
        <p className="text-[#758b77] text-sm">
          {t("totalAccounts")}: {totalAccounts.length} | {t("visibleAccounts")}: {visibleAccounts.length} | {t("lastUpdate")}: {new Date(user?.updatedOn).toLocaleString()}
        </p>
      </div>

    </div>
  );
};

export default withRouter(UserBox)