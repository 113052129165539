import React from 'react';
import ContentLoader from 'react-content-loader';

export const EventsLoader = () => (
  <ContentLoader
    speed={1}
    width={320}
    height={200}
    viewBox="0 0 320 200"
    backgroundColor="#111E12"
    foregroundColor="#1d2d1d"
  >
    <rect x="0" y="0" rx="10" ry="10" width="146" height="125" /> 
    <rect x="156" y="0" rx="10" ry="10" width="146" height="125" />
  </ContentLoader>
);